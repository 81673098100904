import { useSelector } from "react-redux";
import {
  changeEmail,
  changePhoneNumber,
  getAccountState,
} from "../../../redux/slices/accountSlice";
import { useDispatch } from "react-redux";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Icon } from "@iconify/react";
import { Colors } from "../../../utils/colors";
import PopupModalContainer from "../../Containers/PopupModalContainer";
import RectangleButton from "../../Buttons/RectangleButton";
import CustomTextField from "../../CustomTextField";
import { TextField } from "@mui/material";
import {
  EmailAuthProvider,
  PhoneAuthProvider,
  RecaptchaVerifier,
  firebaseAuth,
  sendEmailVerification,
  signInWithPhoneNumber,
  unlink,
  updateEmail,
  updatePassword,
} from "../../../firebase";
import { MixpanelContext } from "../../../context/AnalyticsService";
import { getPhoneNumberSnap } from "../../../utils/FirebaseUtils";
import {
  ChangeAuthEmail,
  OnForgotPasswordPress,
  ReauthenticateEmail,
  ReauthenticatePhone,
} from "../../../utils/ReauthenticateLogin";
import CustomPhoneTextField from "../../TextFields/CustomPhoneTextField";
import { showPhoneInputError } from "../../../utils/phoneUtils";
import { PhoneErrorType } from "@markit/common.utils";

export type EditProfileConvertModalProps = {
  convertModal: boolean;
  setConvertModal: (convertModal: boolean) => void;
  changeModal: boolean;
  setChangeModal: (changeModal: boolean) => void;
  passwordModal: boolean;
  setPasswordModal: (passwordModal: boolean) => void;
};

const EditProfileConvertModal = (props: EditProfileConvertModalProps) => {
  const {
    convertModal,
    setConvertModal,
    changeModal,
    setChangeModal,
    passwordModal,
    setPasswordModal,
  } = props;
  const { accountData } = useSelector(getAccountState).account;
  const mixpanel = useContext(MixpanelContext);
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState({
    status: false,
    message: "",
  });
  const [message, showMessage] = useState<{
    text: String;
    color?: string;
  }>();
  const [showPhoneNumberError, setShowPhoneNumberError] =
    useState<PhoneErrorType>({ isError: false, message: "" });
  const [phoneNumber, setPhoneNumber] = useState("");
  const [currPassword, setCurrPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [verificationId, setVerificationId] = useState("");
  const [verificationCode, setVerificationCode] = useState("");

  const styles = {
    title: { fontSize: 14, fontWeight: "500" },
    smallTitle: { fontSize: 12, fontWeight: "500" },
    errorMessage: { fontSize: 12, color: Colors.RED1, marginBottom: 7 },
  };

  const isPhoneBasedAccount = useMemo(
    () => accountData.phoneNumber !== "",
    [accountData.phoneNumber]
  );

  const displayPhoneModal = useMemo(
    () =>
      (convertModal && !isPhoneBasedAccount) ||
      (changeModal && isPhoneBasedAccount),
    [changeModal, convertModal, isPhoneBasedAccount]
  );

  useEffect(() => {
    if (displayPhoneModal && !window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        "recaptcha-container",
        {
          size: "invisible",
          callback: (response: any) => {
            console.log("Successfully sent OTP");
          },
        },
        firebaseAuth
      );
      window.recaptchaVerifier.verify();
    }
  }, [displayPhoneModal]);

  const closePhoneEmailModal = useCallback(() => {
    setEmail("");
    setCurrPassword("");
    setNewPassword("");
    setConfirmNewPassword("");
    setPhoneNumber("");
    setVerificationCode("");
    setVerificationId("");
    setPasswordError(false);
    setConfirmPasswordError(false);
    setEmailError({ status: false, message: "" });
    showMessage(undefined);
    convertModal
      ? setConvertModal(false)
      : passwordModal
      ? setPasswordModal(false)
      : setChangeModal(false);
  }, [
    convertModal,
    passwordModal,
    setChangeModal,
    setConvertModal,
    setPasswordModal,
  ]);

  const changePassword = useCallback(async () => {
    if (email === "" || email.length > 100 || !email.includes("@")) {
      alert("Email address is poorly formatted. Try again.");
    } else {
      await ReauthenticateEmail(currPassword)
        .then(async () => {
          setPasswordError(newPassword.length < 6);
          setConfirmPasswordError(newPassword !== confirmNewPassword);

          if (newPassword.length < 6 || newPassword !== confirmNewPassword)
            return;

          try {
            const user = firebaseAuth.currentUser;

            if (user) {
              await updatePassword(user, newPassword)
                .then(() => {
                  alert("Password successfully changed");
                  setPasswordModal(false);
                  closePhoneEmailModal();
                })
                .catch((error) => {
                  if (error.code === "auth/invalid-email") {
                    setEmailError({
                      status: true,
                      message: "Badly formatted email",
                    });
                  } else {
                    setEmailError({ status: true, message: error.message });
                  }
                });
            }
          } catch (err: any) {
            console.log("Error " + err.message);
            alert("An error occurred. Please refresh this page and try again.");
          }
        })
        .catch((error) => {
          if (error.code === "auth/wrong-password") {
            alert("Incorrect password. Please try again.");
          } else if (error.code === "auth/missing-password") {
            alert("Please type in your current password.");
          } else {
            alert(error.message);
          }
        });
    }
  }, [
    closePhoneEmailModal,
    confirmNewPassword,
    currPassword,
    email,
    newPassword,
    setPasswordModal,
  ]);

  const convertToEmail = useCallback(async () => {
    const emailLower = email.toLowerCase();
    setPasswordError(newPassword.length < 6);
    setConfirmPasswordError(newPassword !== confirmNewPassword);
    // I use the previous conditionals rather than the error variables
    // because of useState setter having a strange delay...
    if (newPassword.length < 6 || newPassword !== confirmNewPassword) return;
    try {
      const user = firebaseAuth.currentUser;
      if (user) {
        await updateEmail(user, emailLower);
        await updatePassword(user, newPassword);
        await sendEmailVerification(user)
          .then(async () => {
            dispatch(
              changePhoneNumber("", accountData.uid, accountData.phoneNumber)
            );
            dispatch(changeEmail(emailLower, accountData.uid));
            await unlink(user, PhoneAuthProvider.PROVIDER_ID);
            mixpanel.track("Converted Account From Phone Number To Email", {
              distinct_id: accountData.uid,
            });
            setConvertModal(false);
            closePhoneEmailModal();
          })
          .catch((error) => {
            if (error.code === "auth/email-already-exists") {
              setEmailError({
                status: true,
                message: "Email already exists",
              });
            } else if (error.code === "auth/invalid-email") {
              setEmailError({
                status: true,
                message: "Badly formatted email",
              });
            } else {
              setEmailError({ status: true, message: error.message });
            }
          });
      }
    } catch (err: any) {
      if (err.code === "auth/requires-recent-login") {
        alert(
          "Recent login authentication needed: Please log out and sign back in"
        );
      } else {
        console.log("Error: " + err.message);
        alert("An error occurred. Please refresh this page and try again.");
      }
    }
  }, [
    accountData.phoneNumber,
    accountData.uid,
    closePhoneEmailModal,
    confirmNewPassword,
    dispatch,
    email,
    mixpanel,
    newPassword,
    setConvertModal,
  ]);

  const changeCurrentEmail = useCallback(async () => {
    if (email === "" || email.length > 100 || !email.includes("@")) {
      alert("New email address is poorly formatted. Try again.");
    } else {
      await ReauthenticateEmail(newPassword)
        .then(async () => {
          await ChangeAuthEmail(email);
          dispatch(changeEmail(email, accountData.uid));
          setChangeModal(false);
          closePhoneEmailModal();
        })
        .catch((error: any) =>
          alert("There was an error changing your email: " + error.message)
        );
    }
  }, [
    accountData.uid,
    closePhoneEmailModal,
    dispatch,
    email,
    newPassword,
    setChangeModal,
  ]);

  const convertToPhone = useCallback(async () => {
    if (verificationId) {
      await ReauthenticatePhone(verificationId, verificationCode)
        .then(async () => {
          if (convertModal) {
            try {
              const user = firebaseAuth.currentUser;
              if (user) {
                unlink(user, EmailAuthProvider.PROVIDER_ID).catch((err) => {
                  console.log("Error unlinking: " + err.message);
                });
              }
              dispatch(changeEmail("", accountData.uid));
            } catch (err: any) {
              if (err.code === "auth/requires-recent-login") {
                alert(
                  "Recent login authentication needed: Please log out and sign back in"
                );
              } else {
                console.log("Error phone: " + err.message);
                alert(
                  "An error occurred. Please refresh this page and try again."
                );
              }
              return;
            }
          }
          dispatch(
            changePhoneNumber(
              phoneNumber,
              accountData.uid,
              accountData.phoneNumber
            )
          );
          if (convertModal) {
            mixpanel.track("Converted Account From Email To Phone Number", {
              distinct_id: accountData.uid,
            });
            setConvertModal(false);
          } else {
            mixpanel.track("Change Phone Number", {
              distinct_id: accountData.uid,
            });
            setChangeModal(false);
          }
          closePhoneEmailModal();
        })
        .catch((err: any) => {
          if (err.code === "auth/requires-recent-login") {
            alert(
              "Recent login authentication needed: Please log out and sign back in"
            );
          } else {
            console.log("Error: " + err.message);
            alert("An error occurred. Please refresh this page and try again.");
          }
        });
    }
  }, [
    accountData.phoneNumber,
    accountData.uid,
    closePhoneEmailModal,
    convertModal,
    dispatch,
    mixpanel,
    phoneNumber,
    setChangeModal,
    setConvertModal,
    verificationCode,
    verificationId,
  ]);

  const sendVerificationCode = useCallback(async () => {
    if (showPhoneInputError(phoneNumber, setShowPhoneNumberError)) {
      return;
    }
    const phoneNumberSnap = await getPhoneNumberSnap(phoneNumber);
    if (phoneNumberSnap.exists()) {
      showMessage({
        text: "User with specified phone number already exists",
        color: "red",
      });
      return;
    }
    signInWithPhoneNumber(
      firebaseAuth,
      phoneNumber,
      await window.recaptchaVerifier
    )
      .then((confirmationResult) => {
        setVerificationId(confirmationResult.verificationId);
        showMessage({
          text: "Verification code sent.",
        });
      })
      .catch((err) => {
        console.log(err);
        showMessage({
          text: `An error occurred. Please refresh this page and try again.`,
          color: "red",
        });
      });
  }, [phoneNumber]);

  const renderPasswordInput = useCallback(
    (
      id: string,
      newPassword: string,
      setNewPassword: (newPassword: string) => void,
      placeholder: string
    ) => (
      <TextField
        id={id}
        type={"password"}
        value={newPassword}
        placeholder={placeholder}
        error={passwordError && id !== "currPassword"}
        helperText={
          passwordError && id !== "currPassword"
            ? "Password may be invalid"
            : undefined
        }
        onChange={(password: any) => {
          setPasswordError(false);
          setNewPassword(password.target.value);
        }}
        InputLabelProps={{
          shrink: true,
          style: {
            color: "#000",
            fontWeight: "600",
          },
        }}
        InputProps={{
          style: {
            color: "#000",
            borderRadius: 12,
            height: 50,
          },
        }}
        inputProps={{
          autocomplete: "new-password",
          form: {
            autocomplete: "off",
          },
        }}
        sx={{
          borderRadius: 2,
        }}
        variant="outlined"
      />
    ),
    [passwordError]
  );

  return (
    <>
      <div id="recaptcha-container"></div>
      {convertModal || changeModal || passwordModal ? (
        <PopupModalContainer
          headerComp={
            <div>
              <Icon
                icon={displayPhoneModal ? "ion:call" : "ion:mail"}
                height={30}
              />
              <h1
                className="AboutSubtitle"
                style={{ fontSize: 16, paddingTop: 4 }}
              >
                {displayPhoneModal
                  ? convertModal
                    ? "Convert to Phone Account"
                    : "Change Phone"
                  : convertModal
                  ? "Convert to Email Account"
                  : passwordModal
                  ? "Change Password"
                  : "Change Email"}
              </h1>
            </div>
          }
          subHeaderComp={
            <div style={{ marginTop: 7 }}>
              <h1
                className="AboutSubtitle"
                style={{
                  fontSize: 13,
                  fontWeight: 400,
                  color: Colors.GRAY1,
                }}
              >
                {displayPhoneModal
                  ? convertModal
                    ? "Convert to a phone account if you want to follow accounts, register for events with this account, or if you would just like to login via phone number."
                    : "Enter a new phone number for this account to use."
                  : convertModal
                  ? "Convert to an email account if you are a business, organization, or just need multiple people to access or run your account at once."
                  : passwordModal
                  ? "Change your existing password by entering the information below."
                  : "Enter a new email for this account to use."}
              </h1>
            </div>
          }
          valueComp={
            <div className="ColumnNormal" style={{ gap: 14 }}>
              {displayPhoneModal ? (
                <>
                  <CustomPhoneTextField
                    phoneNumber={phoneNumber}
                    onPhoneChange={(value) => setPhoneNumber(value)}
                    showPhoneError={showPhoneNumberError}
                    containerStyles={{ backgroundColor: Colors.GRAY6 }}
                    placeholder="Enter Phone Number"
                  />
                  <RectangleButton
                    buttonLabel="Send Verification Code"
                    onPress={sendVerificationCode}
                    altTextColor={Colors.WHITE}
                    altColor={Colors.BLACK}
                    altPaddingVert={14}
                  />
                  {message ? (
                    <span
                      style={{
                        fontSize: 12,
                        color: message.color,
                        textAlign: "center",
                      }}
                    >
                      {message.text}
                    </span>
                  ) : null}
                  <CustomTextField
                    value={verificationCode}
                    inputMode="numeric"
                    placeholder="Enter Verification Code"
                    borderRadius={12}
                    backgroundColor={Colors.GRAY6}
                    onChange={(change: any) => {
                      setVerificationCode(change.target.value);
                    }}
                    maxLength={6}
                    altMarginBottom={0}
                  />
                  <RectangleButton
                    buttonLabel="Confirm"
                    onPress={convertToPhone}
                    altTextColor={Colors.WHITE}
                    altColor={Colors.BLACK}
                    altPaddingVert={14}
                    disabled={!verificationId}
                  />
                </>
              ) : (
                <>
                  <CustomTextField
                    value={email}
                    placeholder="Enter Email"
                    inputMode="text"
                    borderRadius={12}
                    noAutocomplete
                    backgroundColor={Colors.WHITE}
                    onChange={(change: any) => {
                      setEmail(change.target.value);
                    }}
                    error={emailError.status}
                    altMarginBottom={0}
                    altPadding="12px 14px"
                    altFontSize="16px"
                  />
                  {passwordModal
                    ? renderPasswordInput(
                        "currPassword",
                        currPassword,
                        setCurrPassword,
                        "Enter Current Password"
                      )
                    : null}
                  {renderPasswordInput(
                    "password",
                    newPassword,
                    setNewPassword,
                    convertModal || passwordModal
                      ? "Enter New Password"
                      : "Current Password"
                  )}
                  {convertModal || passwordModal ? (
                    renderPasswordInput(
                      "newpassword",
                      confirmNewPassword,
                      setConfirmNewPassword,
                      "Confirm New Password"
                    )
                  ) : (
                    <span
                      onClick={() => OnForgotPasswordPress(accountData.email)}
                      style={{
                        ...styles.title,
                        cursor: "pointer",
                        fontWeight: "600",
                      }}
                    >
                      Forgot Password?
                    </span>
                  )}
                  <div className="ColumnNormal">
                    {emailError.status ? (
                      <span style={styles.errorMessage}>
                        * {emailError.message}
                      </span>
                    ) : null}
                    {passwordError ? (
                      <span style={styles.errorMessage}>
                        * Password must be at least 6 characters long
                      </span>
                    ) : null}
                    {confirmPasswordError ? (
                      <span style={styles.errorMessage}>
                        * Passwords don&apos;t match
                      </span>
                    ) : null}
                  </div>
                </>
              )}
            </div>
          }
          fixedBottomContent={
            !displayPhoneModal ? (
              <div className="ColumnCenter" style={{ gap: 14 }}>
                <hr style={{ margin: "auto" }} />
                <div style={{ marginInline: 14 }}>
                  <RectangleButton
                    buttonLabel={
                      convertModal
                        ? "Convert"
                        : passwordModal
                        ? "Change Password"
                        : "Save"
                    }
                    onPress={
                      convertModal
                        ? convertToEmail
                        : passwordModal
                        ? changePassword
                        : changeCurrentEmail
                    }
                    altTextColor={Colors.WHITE}
                    altColor={Colors.BLACK}
                    altPaddingVert={14}
                  />
                </div>
              </div>
            ) : null
          }
          noExit
          closeOnOutsidePress
          closeModal={closePhoneEmailModal}
        />
      ) : null}
    </>
  );
};

export default EditProfileConvertModal;
