import { Event, EventType } from "@markit/common.types";
import { API_BASE } from "../API";
import { firebaseStorage, getDownloadURL, ref, uploadBytes } from "../firebase";
import Compressor from "compressorjs";
import { isEventExternalLink } from "@markit/common.utils";
import { ListResult } from "firebase/storage";

type MediaUpload = { blobUrl: string; uploadCode: string };

export enum MEDIA_UPLOAD_CODES {
  SUCCESS = "Success",
  TOO_BIG = "Too Big",
  INVALID = "Invalid",
  PERMISSIONS = "Permissions",
}

export const MAX_IMAGE_MEDIA_SIZE_TEXT = 4000000;
export const MAX_IMAGE_MEDIA_SIZE_NON_TEXT = 8000000;
export const MIN_IMAGE_MEDIA_SIZE_TO_COMPRESS = 1000000;

export const errorAlertText = (code: string, text: boolean) => {
  switch (code) {
    case MEDIA_UPLOAD_CODES.TOO_BIG:
      return `Image is too big (max. ${
        (text ? MAX_IMAGE_MEDIA_SIZE_TEXT : MAX_IMAGE_MEDIA_SIZE_NON_TEXT) /
        1000000
      }MB)`;
    case MEDIA_UPLOAD_CODES.INVALID:
      return "Invalid Media Type";
    case MEDIA_UPLOAD_CODES.PERMISSIONS:
      return "Sorry, please go into settings and enable camera permissions";
    default:
      return "";
  }
};

export const errorSubAlertText = (code: string) => {
  switch (code) {
    case MEDIA_UPLOAD_CODES.TOO_BIG:
      return "Try again with a smaller image";
    case MEDIA_UPLOAD_CODES.INVALID:
      return "Try again with a valid media type.\nValid media types include: jpeg, jpg, gif, png";
    case MEDIA_UPLOAD_CODES.PERMISSIONS:
      return "";
    default:
      return "";
  }
};

export const uploadMedia = async (
  event: React.ChangeEvent<HTMLInputElement>,
  square: boolean,
  text: boolean
): Promise<MediaUpload> => {
  try {
    const myFile = event.target.files && event.target.files[0];

    if (myFile) {
      if (
        myFile.size >
        (text ? MAX_IMAGE_MEDIA_SIZE_TEXT : MAX_IMAGE_MEDIA_SIZE_NON_TEXT)
      ) {
        return {
          blobUrl: "",
          uploadCode: MEDIA_UPLOAD_CODES.TOO_BIG,
        };
      }
      const convertData = await convertImage(myFile, square);
      if (convertData.uploadCode === MEDIA_UPLOAD_CODES.SUCCESS) {
        // converting image doesn't always exactly convert so we should check the size again because the size limit on texts is strict
        if (text) {
          const response = await fetch(convertData.blobUrl);
          const blob = await response.blob();
          if (blob.size > MAX_IMAGE_MEDIA_SIZE_TEXT) {
            return {
              blobUrl: "",
              uploadCode: MEDIA_UPLOAD_CODES.TOO_BIG,
            };
          }
        }

        return {
          blobUrl: convertData.blobUrl,
          uploadCode: MEDIA_UPLOAD_CODES.SUCCESS,
        };
      } else {
        return convertData;
      }
    } else {
      return {
        blobUrl: "",
        uploadCode: MEDIA_UPLOAD_CODES.INVALID,
      };
    }
  } catch (e: any) {
    return {
      blobUrl: "",
      uploadCode: MEDIA_UPLOAD_CODES.INVALID,
    };
  }
};

// image compress and manipulation
export const compressAndManipulateImage = async (
  url: string,
  compressionQuality: number,
  square: boolean
): Promise<MediaUpload> => {
  return new Promise<MediaUpload>((resolve, reject) => {
    try {
      const img = new Image();
      img.src = url;

      img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        if (!ctx) {
          console.error("Canvas context not supported.");
          reject();
          return;
        }
        if (square) {
          const size = Math.min(img.width, img.height);
          canvas.width = size;
          canvas.height = size;
          const offsetX = (size - img.width) / 2;
          const offsetY = (size - img.height) / 2;
          // Resize canvas and redraw image based on new dimensions
          ctx.clearRect(0, 0, canvas.width, canvas.height);
          ctx.drawImage(img, offsetX, offsetY);
        } else {
          canvas.width = img.width;
          canvas.height = img.height;
          // Resize canvas and redraw image based on new dimensions
          ctx.clearRect(0, 0, canvas.width, canvas.height);
          ctx.drawImage(img, 0, 0);
        }

        // Convert canvas to blob
        canvas.toBlob(
          (blob) => {
            if (blob) {
              const blobURL = URL.createObjectURL(blob);
              resolve({
                blobUrl: blobURL,
                uploadCode: MEDIA_UPLOAD_CODES.SUCCESS,
              });
            } else {
              reject();
            }
          },
          "image/jpeg", // required to specify jpeg to utilize compressionQuality
          compressionQuality
        );
      };

      img.onerror = (error) => {
        reject();
      };
    } catch (e: any) {
      reject();
    }
  });
};

// Utilises HTML5's canvas properties to redraw and thus compress image
export const convertImage = (
  myFile: File,
  square: boolean
): Promise<MediaUpload> => {
  return new Promise<MediaUpload>((resolve, reject) => {
    const fileReader = new FileReader();
    const compressionQuality =
      myFile.size > MIN_IMAGE_MEDIA_SIZE_TO_COMPRESS ? 0.7 : 0.9; // Healthy cq number. 0.7 compression quality is needed to make image roughly 90% of uploaded size. At 1, it becomes 4x size

    fileReader.onload = async () => {
      try {
        const compressData = await compressAndManipulateImage(
          fileReader.result as string,
          compressionQuality,
          square
        );
        resolve(compressData);
      } catch (e: any) {
        reject();
      }
    };

    fileReader.onerror = (error) => {
      reject();
    };

    if (myFile) {
      fileReader.readAsDataURL(myFile);
    } else {
      reject();
    }
  });
};

// TODO (jonathan): Peter set up this complicated logic, so I can't apply the saveMediaToFirestore helper function here like we do with all other places we save media
// Handles creating and storing the photo into firebase storage by creating the blob and using uploadBytes
// For external events, uses backend proxy to handle storing a third-party external photo url into firebase storage
// Due to CORS, we have to use our backend as a proxy to bypass the security rules
export const storeEventImageIntoFirebaseStorage = async (
  event: Event
): Promise<string> => {
  try {
    let blob: Blob = new Blob();
    // Investigate: For the editing event case, if image does not change, I had to make it enter the proxy case. Not sure why we have to use proxy now when we didn't before, it was fine without it
    // Use proxy if event type is an external event OR if Markit Event with external link
    if (
      event.photoURL.startsWith("https://firebasestorage") ||
      isEventExternalLink(event.eventType) ||
      (event.eventType === EventType.MARKIT && event.externalLink)
    ) {
      const proxyUrl = `${API_BASE}eventScraper/fetchExternalEventPhotoProxy?url=${encodeURIComponent(
        event.photoURL
      )}`;
      const response = await fetch(proxyUrl);
      if (!response.ok) {
        // If the proxy does not work, try to fetch the photoUrl by itself.
        // Can hit this case if type is Markit and has externalLink, but image wasn't from third party
        const altResponse = await fetch(event.photoURL);
        if (!altResponse.ok) {
          throw new Error("Network response was not ok");
        }
        const altBlob = await altResponse.blob();
        blob = altBlob;
      } else {
        blob = await response.blob();
      }

      // compress and convert image to a square
      // has to be done after the proxy stuff
      const uploadData = await compressAndManipulateImage(
        URL.createObjectURL(blob),
        0.7,
        true
      );

      if (uploadData.uploadCode !== MEDIA_UPLOAD_CODES.SUCCESS) {
        alert("Error converting image from external link");
      } else {
        blob = await fetch(uploadData.blobUrl).then((r) => r.blob());
      }
    } else {
      // Normal uploaded images from user
      blob = await fetch(event.photoURL).then((r) => r.blob());
    }
    const imageRef = ref(
      firebaseStorage,
      event.createdBy + "/eventPhotos/" + event.id
    );
    const metadata = {
      contentType: "image/jpeg",
    };
    return new Promise<string>(async (resolve, reject) => {
      // TODO (jonathan): we probably don't need this anymore since I added compressAndManipulateImage to apply to the original external link
      // This is just reducing the size of the image by a quality factor if it's a massive scraped image. Eventbrite and luma can only upload a max of ~10MB images
      // Just leaving for now since no reason to change it and can figure out if we can remove later
      if (blob.size > 8000000) {
        new Compressor(blob, {
          quality: 0.8,
          success: async (compressedResult) => {
            await uploadBytes(imageRef, compressedResult, metadata);
            const url = await getDownloadURL(imageRef);
            resolve(url);
          },
          error: (error) => {
            reject(error);
          },
        });
      } else {
        uploadBytes(imageRef, blob, metadata)
          .then(async () => {
            const url = await getDownloadURL(imageRef);
            resolve(url);
          })
          .catch((error) => {
            reject(error);
          });
      }
    });
  } catch (err: any) {
    console.log(err.message);
  }
  return "";
};

// Saves an image to a specified pathString in firebase storage
export const saveMediaToFirestore = async (
  url: string,
  pathString: string
): Promise<string> => {
  const blob = await fetch(url).then((r) => r.blob());
  const imageRef = ref(firebaseStorage, pathString);
  return await uploadBytes(imageRef, blob).then(async () => {
    const url = await getDownloadURL(imageRef);
    return url;
  });
};

// Visually remove the specified smaple photos from the selectable images
export const filteredSampleImages = async (listResult: ListResult) => {
  const defaultImages = await Promise.all(
    listResult.items.map(async (item) => {
      const url = await getDownloadURL(item);
      return url;
    })
  );
  const filteredImages = defaultImages.filter(
    (image) =>
      !image.includes("auraOne.jpeg") && !image.includes("willSmithMarkit.jpg")
  );
  return filteredImages;
};
