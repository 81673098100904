import "../../css/Containers/ContainerStyles.css";
import { Icon } from "@iconify/react";
import { Colors } from "../../utils/colors";
import PopupModalContainer from "./PopupModalContainer";
import RectangleButton from "../Buttons/RectangleButton";
import { MARKIT_HOTLINE_NUMBER } from "@markit/common.utils";
import { formatPhoneNumber } from "../../utils/FormatPhoneNumber";

type HelpModalProps = {
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
};

const HelpModal = (props: HelpModalProps) => {
  const { showModal, setShowModal } = props;

  return (
    <>
      {showModal ? (
        <PopupModalContainer
          headerComp={
            <div>
              <Icon icon="ion:chatbubble-ellipses-outline" height={40} />
              <h1
                className="AboutSubtitle"
                style={{ fontSize: 17, paddingTop: 4 }}
              >
                Contact Our Hotline at{" "}
                {formatPhoneNumber(MARKIT_HOTLINE_NUMBER)}
              </h1>
            </div>
          }
          subHeaderComp={
            <div style={{ marginTop: 7 }}>
              <span style={{ fontSize: 14, color: Colors.GRAY1 }}>
                Ask us questions or report issues.
                <br />
                We will get back to you as soon as we can.
              </span>
            </div>
          }
          valueComp={
            <RectangleButton
              buttonLabel="Done"
              onPress={() => setShowModal(false)}
              altTextColor={Colors.BLACK}
              altColor={Colors.GRAY6}
            />
          }
          noExit
          closeOnOutsidePress
          closeModal={() => setShowModal(false)}
        />
      ) : null}
    </>
  );
};

export default HelpModal;
