import "../../css/GlobalStyles.css";
import {
  CSSProperties,
  ReactNode,
  memo,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { DropdownMenu, DropdownMenuItem } from "../DropdownMenu";
import { MixpanelContext } from "../../context/AnalyticsService";
import { getAccountState } from "../../redux/slices/accountSlice";
import { useLocation } from "react-router-dom";
import MassTextsPopupPanel from "../CreatorDashboard/MassTexts/MassTextsPopupPanel";
import AudienceListPopupPanel from "../CreatorDashboard/CreatorProfile/AudienceLists/AudienceListPopupPanel";
import { useNavigate } from "../../hooks/useNavigate";
import { hasSubscription, MARKITAI } from "@markit/common.utils";
import { NavigationId } from "../../navigation/AppParamList";
import { MarkitPlusModal } from "../Subscription/MarkitPlusModal";

type MainActionsDropdownMenuProps = {
  children: ReactNode;
  sourceLocation: string;
  overlayStyles?: CSSProperties;
};

const MainActionsDropdownMenu = memo(function MainActionsDropdownMenuFn(
  props: MainActionsDropdownMenuProps
) {
  const { children, sourceLocation, overlayStyles } = props;
  const navigate = useNavigate();
  const mixpanel = useContext(MixpanelContext);
  const location = useLocation();
  const { account } = useSelector(getAccountState);
  const { accountData } = account;
  const [massTextVisible, setMassTextVisible] = useState(false);
  const [listPopupVisible, setListPopupVisible] = useState(false);
  const [subModalVisible, setSubModalVisible] = useState(false);

  const redirectToCreate = useCallback(() => {
    mixpanel.track("Pressed Create An Event", {
      pathname: location.pathname,
      button_location: sourceLocation, // "top header",
      platform: "webapp",
    });
    window.location.href = `${MARKITAI}/create`;
  }, [location.pathname, mixpanel, sourceLocation]);

  const eventLinkOnPress = useCallback(() => {
    mixpanel.track("Pressed Create External Event Link", {
      distinct_id: accountData.uid,
      platform: "webapp",
      type: "event_link",
      button_location: sourceLocation,
    });
    navigate(NavigationId.CREATE_LINK_EVENT);
  }, [mixpanel, accountData.uid, sourceLocation, navigate]);

  const genericLinkOnPress = useCallback(() => {
    mixpanel.track("Pressed Create External Event Link", {
      distinct_id: accountData.uid,
      platform: "webapp",
      type: "generic_link",
      button_location: sourceLocation,
    });
    navigate("/createLink");
  }, [accountData.uid, mixpanel, navigate, sourceLocation]);

  const dropdownItems: DropdownMenuItem[] = useMemo(
    () => [
      {
        title: "New Text",
        icon: "ion:chatbubble-ellipses",
        key: "new_text",
        onPress: () => setMassTextVisible(true),
      },
      {
        title: "Create Link",
        icon: "ion:link",
        key: "create_link",
        onPress: genericLinkOnPress,
      },
      {
        title: "Create Event",
        icon: "ion:calendar",
        key: "create_event",
        onPress: redirectToCreate,
      },
      {
        title: "Link an Event",
        icon: "ion:link",
        key: "link_event",
        onPress: eventLinkOnPress,
      },
      {
        title: "New List",
        icon: "ion:list",
        key: "new_list",
        onPress: () => setListPopupVisible(true),
      },
      {
        title: "Import Contacts",
        icon: "ion:cloud-upload",
        key: "import_contacts",
        onPress: () => {
          if (!hasSubscription(accountData)) {
            setSubModalVisible(true);
            return;
          }
          navigate("/import");
        },
      },
    ],
    [
      accountData,
      eventLinkOnPress,
      genericLinkOnPress,
      navigate,
      redirectToCreate,
    ]
  );

  return (
    <>
      <DropdownMenu
        dropdownItems={dropdownItems}
        placement="bottomRight"
        overlayStyle={overlayStyles}
      >
        {children}
      </DropdownMenu>
      {massTextVisible ? (
        <MassTextsPopupPanel
          isVisible={massTextVisible}
          setIsVisible={setMassTextVisible}
        />
      ) : null}
      {listPopupVisible ? (
        <AudienceListPopupPanel
          isVisible={listPopupVisible}
          setIsVisible={setListPopupVisible}
          existingList={undefined}
          setExistingList={() => {}}
        />
      ) : null}
      {subModalVisible ? (
        <MarkitPlusModal closeModal={() => setSubModalVisible(false)} />
      ) : null}
    </>
  );
});

export default MainActionsDropdownMenu;
