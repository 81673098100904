import { MARKIT_FREE_CREATOR_NUMBER } from "@markit/common.utils";
import { API } from "../API";
import { formatPhoneNumber } from "./FormatPhoneNumber";
import {
  AccountData,
  AudienceListMembers,
  FollowSourceType,
  FormAnswersV2,
} from "@markit/common.types";
import { isDesktop } from "react-device-detect";
import {
  Batch,
  getUserAudienceListData,
  getUserAudienceListMemberRef,
  getUserAudienceListRef,
} from "./FirebaseUtils";

export const followUser = async (
  userPhone: string,
  userFullName: string,
  userToFollow: AccountData,
  uid?: string,
  answers?: FormAnswersV2
): Promise<string> => {
  const followSourceType = isDesktop
    ? FollowSourceType.DESKTOP_WEB
    : FollowSourceType.MOBILE_WEB;
  const responseUid: string = await (uid
    ? API.text.sendFollowUserMessage({
        uid: uid,
        fullName: userFullName,
        userToFollowUid: userToFollow.uid,
        followSourceType: followSourceType,
        answers: answers,
      })
    : API.text.sendFollowUserMessage({
        toPhoneNumber: userPhone,
        fullName: userFullName,
        userToFollowUid: userToFollow.uid,
        followSourceType: followSourceType,
        answers: answers,
      })
  )
    .then((response) => {
      const { uid, alreadyFollowing, optedOut, isFreeCreator } = response;
      if (optedOut) {
        alert(
          "You are currently opted out from texts from " +
            (isFreeCreator
              ? "the Markit free creator phone"
              : userToFollow.fullName) +
            ". Text START to " +
            (isFreeCreator
              ? formatPhoneNumber(MARKIT_FREE_CREATOR_NUMBER)
              : formatPhoneNumber(userToFollow.customer.phone)) +
            " if you want to follow"
        );
        return "";
      } else if (alreadyFollowing) {
        alert("You are already following this profile!");
        return "";
      }

      return uid;
    })
    .catch((err: any) => {
      console.log(err.message);
      alert(
        `Failed following the user (Error: ${err.message}). Try again later.`
      );
      return "";
    });
  return responseUid;
};

export const unfollowUser = async (
  userId: string,
  userToUnfollowUid: string
) => {
  try {
    await API.user.unfollowUser({
      uid: userId,
      userToUnfollowUid: userToUnfollowUid,
    });
  } catch (e: any) {
    console.error("Error unfollowing the user: " + e.message);
  }
};

/**
 * Audience List Functions
 * TODO (jonathan): needs to all be moved into backend
 */

/**
 * Adds user to audience list
 */
export const addAudienceListMember = async (
  uid: string,
  audienceListId: string,
  member: AudienceListMembers
): Promise<void> => {
  const batch = new Batch("Error creating audience list member data");
  const audienceListMemberRef = getUserAudienceListMemberRef(
    uid,
    audienceListId,
    member.uid
  );

  batch.set(audienceListMemberRef, member);

  const audienceListRef = getUserAudienceListRef(uid, audienceListId);
  const audienceList = await getUserAudienceListData(uid, audienceListId);
  const currentNumber = audienceList ? audienceList.numberMembers : 0;
  const audienceListUpdate = {
    numberMembers: currentNumber + 1,
  };
  const newAudienceList = { ...audienceList, ...audienceListUpdate };
  batch.update(audienceListRef, newAudienceList);

  try {
    await batch.commit();
  } catch (e: any) {
    alert("Error adding user's audience list member: " + e.message);
  }
};

/**
 * Adds a user to a list of audience lists
 */
export const addUserToAudienceLists = async (
  creatorUid: string,
  followerUid: string,
  listsToAddTo: string[]
) => {
  if (listsToAddTo.length > 0) {
    for (let i = 0; i < listsToAddTo.length; i++) {
      const newMember: AudienceListMembers = { uid: followerUid };
      await addAudienceListMember(creatorUid, listsToAddTo[i], newMember);
    }
  }
};

/**
 * Removes user to audience list
 */
export const removeAudienceListMember = async (
  creatorUid: string,
  followerUid: string,
  listId: string
): Promise<void> => {
  const batch = new Batch("Error removing audience list member data");
  const audienceListMemberRef = getUserAudienceListMemberRef(
    creatorUid,
    listId,
    followerUid
  );

  batch.delete(audienceListMemberRef);

  const audienceListRef = getUserAudienceListRef(creatorUid, listId);
  const audienceList = await getUserAudienceListData(creatorUid, listId);
  const currentNumber = audienceList ? audienceList.numberMembers : 0;
  const audienceListUpdate = {
    numberMembers: currentNumber - 1,
  };
  const newAudienceList = { ...audienceList, ...audienceListUpdate };
  batch.update(audienceListRef, newAudienceList);

  try {
    await batch.commit();
  } catch (e: any) {
    alert("Error adding user's audience list member: " + e.message);
  }
};
