import Input from "react-phone-number-input/input";
import CustomPhoneInput from "./CustomPhoneInput";
import { CSSProperties } from "react";
import { PhoneErrorType } from "@markit/common.utils";

type CustomPhoneTextFieldProps = {
  phoneNumber: string;
  onPhoneChange: (value: any) => void;
  showPhoneError: PhoneErrorType;
  placeholder: string;
  disabled?: boolean;
  containerStyles?: CSSProperties;
  endAdornment?: JSX.Element;
};

/**
 * To be used for text fields with the verified text in the right of the text box
 */
const CustomPhoneTextField = (props: CustomPhoneTextFieldProps) => {
  const {
    phoneNumber,
    onPhoneChange,
    showPhoneError,
    placeholder,
    disabled,
    containerStyles,
    endAdornment,
  } = props;

  return (
    <div className="RowNormal">
      <Input
        placeholder={placeholder}
        value={phoneNumber}
        onChange={(value: any) => {
          onPhoneChange(value);
        }}
        inputComponent={CustomPhoneInput}
        error={showPhoneError}
        defaultCountry={
          phoneNumber && !phoneNumber.startsWith("+") ? undefined : "US"
        }
        inputStyles={containerStyles}
        disabled={disabled}
        endAdornment={endAdornment}
      />
    </div>
  );
};

export default CustomPhoneTextField;
