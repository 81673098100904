import { Colors } from "../../../../utils/colors";
import CustomCheckbox from "../../../CustomCheckbox";
import { GenericActionItem } from "../../../DisplayItem/GenericActionItem";

type MassTextsActionItemProps = {
  title: string;
  icon: JSX.Element;
  onPress?: () => void;
  altIconBackgroundColor?: string;
  isCheckSelected?: boolean;
  checkColor?: string;
  alternateRightIcon?: JSX.Element;
  containerStyles?: React.CSSProperties;
  subtext?: string;
  disabled?: boolean;
};

export const MassTextsActionItem = (props: MassTextsActionItemProps) => {
  const {
    title,
    icon,
    onPress,
    altIconBackgroundColor,
    isCheckSelected,
    checkColor,
    alternateRightIcon,
    containerStyles,
    subtext,
    disabled,
  } = props;
  const styles = {
    actionItemIcon: {
      height: 48,
      width: 48,
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      borderRadius: 8,
      cursor: "pointer",
      border: "0.5px solid #EDEDED",
    },
  };

  return (
    <GenericActionItem
      onPress={onPress}
      containerStyles={containerStyles}
      disabled={disabled}
      textComp={
        <div className="ColumnNormalSelect" style={{ gap: 4 }}>
          <span style={{ fontWeight: "500", fontSize: 14 }}>{title}</span>
          {subtext ? (
            <span style={{ fontSize: 12, color: Colors.GRAY1 }}>{subtext}</span>
          ) : null}
        </div>
      }
      leftComp={
        <div
          style={{
            ...styles.actionItemIcon,
            backgroundColor: altIconBackgroundColor ?? Colors.GRAY6,
          }}
        >
          {icon}
        </div>
      }
      rightComp={
        alternateRightIcon ||
        (checkColor ? (
          <CustomCheckbox
            checked={isCheckSelected}
            onChange={onPress}
            sx={{ padding: 0, opacity: onPress ? 1 : 0.7 }}
            altColor={checkColor}
          />
        ) : undefined)
      }
    />
  );
};
