import { AudienceList } from "@markit/common.types";
import { useSelector } from "react-redux";
import { getAccountState } from "../../../../../redux/slices/accountSlice";
import { useCallback, useMemo, useState } from "react";
import { filter } from "lodash";
import FlatList from "flatlist-react/lib";
import SearchBoxContainer from "../../../../Containers/SearchBoxContainer";
import IntermediaryModalContainer from "../../../../Containers/IntermediaryModalContainer";
import { SelectRecipientGroupType } from "../SelectRecipientsPanel";
import { HorizontalDivider } from "../../../../Dividers/HorizontalDivider";
import { Icon } from "@iconify/react";
import { Colors } from "../../../../../utils/colors";
import { MassTextsActionItem } from "../../Items/MassTextsActionItem";
import { EmptyStateFlatlist } from "../../../../EmptyStates/EmptyStateFlatlist";
import { sortAudienceListsDisplay } from "@markit/common.utils";

type SelectRecipientsAudienceListLibraryProps = {
  excludingMode: boolean;
  updateSelectType: (selectType: Partial<SelectRecipientGroupType>) => void;
};

const SelectRecipientsAudienceListLibrary = (
  props: SelectRecipientsAudienceListLibraryProps
) => {
  const { excludingMode, updateSelectType } = props;
  const { audienceLists } = useSelector(getAccountState).account;
  const [searchTerm, setSearchTerm] = useState("");

  const containsList = useCallback((list: AudienceList, query: string) => {
    return list.name.toLowerCase().includes(query.toLowerCase());
  }, []);

  const handleSearch = (text: string) => {
    setSearchTerm(text);
  };

  const itemsToShow: AudienceList[] = useMemo(() => {
    const userAudienceLists: AudienceList[] =
      sortAudienceListsDisplay(audienceLists);
    let searchResults: AudienceList[] = userAudienceLists;
    if (searchTerm !== "") {
      searchResults = filter(userAudienceLists, (list: AudienceList) => {
        return containsList(list, searchTerm);
      });
    }
    return searchResults;
  }, [audienceLists, searchTerm, containsList]);

  return (
    <IntermediaryModalContainer
      body={
        <div className="ColumnNormal">
          <SearchBoxContainer
            value={searchTerm}
            placeholder={"Search Lists..."}
            onChange={(e) => {
              handleSearch(e.target.value);
            }}
            containerStyles={{ marginTop: 0, marginBottom: 14 }}
          />
          <FlatList
            list={itemsToShow}
            renderItem={(item: AudienceList) => (
              <>
                <MassTextsActionItem
                  title={item.name}
                  subtext={`${item.numberMembers} Member${
                    item.numberMembers !== 1 ? "s" : ""
                  }`}
                  icon={
                    <Icon
                      icon="ion:list"
                      height={32}
                      color={excludingMode ? Colors.RED3 : Colors.BLUE5}
                    />
                  }
                  onPress={() => updateSelectType({ selectedItem: item })}
                  alternateRightIcon={
                    <Icon
                      icon="ion:chevron-right"
                      height={16}
                      color={Colors.GRAY1}
                    />
                  }
                />
                <HorizontalDivider altMargin={10} />
              </>
            )}
            renderWhenEmpty={() => (
              <EmptyStateFlatlist
                containerStyles={{ paddingTop: 200 }}
                isLoading={false}
                searchTerm={searchTerm}
                nonSearchEmptyView={<></>}
              />
            )}
          />
        </div>
      }
    />
  );
};

export default SelectRecipientsAudienceListLibrary;
