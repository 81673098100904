export const MARKIT_PIXEL_ID = "832853797916929";
export const GLOWBOSTON_PIXEL_ID = "952171400043541";
export const GLOWBOSTON_UID = "8sZ4WZ1voFPb2fmeiYi2UGiTzQ52";

export const initializeFBPixel = () => {
  // Check if fbq is already defined to avoid multiple initializations
  if ((window as any).fbq) {
    console.log("Facebook Pixel is already initialized.");
    return;
  }

  // Define the fbq function
  (window as any).fbq = function (...args: any[]) {
    (window as any).fbq.callMethod
      ? (window as any).fbq.callMethod.apply((window as any).fbq, args)
      : (window as any).fbq.queue.push(args);
  };

  // Initialize the queue
  if (!(window as any)._fbq) (window as any)._fbq = (window as any).fbq;
  (window as any).fbq.push = (window as any).fbq;
  (window as any).fbq.loaded = true;
  (window as any).fbq.version = "2.0";
  (window as any).fbq.queue = [];

  // Load the Facebook Pixel script
  const script: HTMLScriptElement = document.createElement("script");
  script.async = true;
  script.src = "https://connect.facebook.net/en_US/fbevents.js";

  // Insert the script tag into the document
  const firstScript: HTMLScriptElement | null =
    document.getElementsByTagName("script")[0];
  if (firstScript && firstScript.parentNode) {
    firstScript.parentNode.insertBefore(script, firstScript);
  }

  // Initialize the pixel with the provided ID
  (window as any).fbq("init", GLOWBOSTON_PIXEL_ID);
};

export const pageViewFBEvent = () => {
  fbq("trackSingle", GLOWBOSTON_PIXEL_ID, "PageView", {});
};

export const landingPageFBEvent = (isMarkit: boolean) => {
  fbq(
    "trackSingle",
    isMarkit ? MARKIT_PIXEL_ID : GLOWBOSTON_PIXEL_ID,
    "ViewContent",
    {}
  );
};

export const addToCartFBEvent = (isMarkit: boolean) => {
  fbq(
    "trackSingle",
    isMarkit ? MARKIT_PIXEL_ID : GLOWBOSTON_PIXEL_ID,
    "AddToCart",
    {}
  );
};

export const initiateCheckoutFBEvent = (isMarkit: boolean) => {
  fbq(
    "trackSingle",
    isMarkit ? MARKIT_PIXEL_ID : GLOWBOSTON_PIXEL_ID,
    "InitiateCheckout",
    {}
  );
};

export const purchaseFBEvent = () => {
  fbq("trackSingle", GLOWBOSTON_PIXEL_ID, "Purchase", {});
};

// export const addPaymentInfoFBEvent = () => {
//   fbq("track", "AddPaymentInfo");
// };

export const standardLeadFBEvent = () => {
  fbq("track", "Lead");
};

// GOOGLE TAGS
export const standardLeadGTAGEvent = () => {
  gtag("event", "conversion", {
    send_to: "AW-11210823461/YeSvCKypv7YYEKWu3uEp",
  });
};
