import { SubSelectionItem, Event } from "@markit/common.types";
import { useSelector } from "react-redux";
import { getAccountState } from "../../../../redux/slices/accountSlice";
import { questionInputName } from "../../../../utils/eventUtils/formUtils";
import { Colors } from "../../../../utils/colors";
import { RecipientsAddAllItem } from "./RecipientsAddAllItem";
import { getCategoryLabelName } from "@markit/common.utils";

type SelectRecipientCategoryItemProps = {
  item: SubSelectionItem;
  onPress: () => void;
  excludingMode: boolean;
  event: Event | undefined;
  isSelected: boolean;
  disabled?: boolean;
};

const SelectRecipientCategoryItem = (
  props: SelectRecipientCategoryItemProps
) => {
  const { item, onPress, excludingMode, event, isSelected, disabled } = props;
  const { savedQuestions } = useSelector(getAccountState).account;

  const categoryLabel = getCategoryLabelName(
    item,
    event ? event.customTickets : [],
    savedQuestions
  );
  const foundSavedQuestion = savedQuestions.find(
    (question) =>
      question.id === (item as SubSelectionItem).id &&
      (item as SubSelectionItem).formOption === ""
  );
  const formQuestionSubtext = foundSavedQuestion
    ? questionInputName(foundSavedQuestion.formInput.inputType)
    : "";

  return (
    <RecipientsAddAllItem
      label={categoryLabel}
      sublabel={
        formQuestionSubtext ? (
          <span
            style={{
              fontSize: 12,
              marginTop: 3,
              color: Colors.GRAY2,
            }}
          >
            {formQuestionSubtext}
          </span>
        ) : undefined
      }
      excludingMode={excludingMode}
      isAllSelected={isSelected}
      selectRecipients={[]}
      isCategories
      onPress={onPress}
      disabled={disabled}
    />
  );
};

export default SelectRecipientCategoryItem;
