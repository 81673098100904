import { m } from "framer-motion";

type IntermediaryModalContainerProps = {
  body: React.ReactNode;
  alternateKey?: string;
  disableScroll?: boolean;
};

// Handles the intermediary modal screens when transitioning from one screen to another within the modal
const IntermediaryModalContainer = (props: IntermediaryModalContainerProps) => {
  const { body, alternateKey, disableScroll } = props;

  return (
    <m.div
      key={alternateKey ?? "masstextsscreen"}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.2, type: "tween" }}
      style={
        disableScroll
          ? {}
          : { height: "calc(100vh - 120px)", overflow: "scroll" }
      }
      className="HideScrollbar"
    >
      {body}
    </m.div>
  );
};

export default IntermediaryModalContainer;
