import React, { useCallback, useContext, useState } from "react";
import "../../css/GlobalStyles.css";
import { Colors } from "../../utils/colors";
import PopupModalContainer from "../Containers/PopupModalContainer";
import { AccountData } from "@markit/common.types";
import { Checkbox } from "@mui/material";
import checkbox from "@iconify/icons-mdi/checkbox-outline";
import RectangleButton from "../Buttons/RectangleButton";
import { useDispatch, useSelector } from "react-redux";
import { setShowedWeeklyCommunityUpdates } from "../../redux/slices/accountSlice";
import { AppState } from "../../redux/store";
import { Icon } from "@iconify/react";
import { useTheme } from "../../hooks/useTheme";
import { followUser } from "../../utils/FollowUser";
import { getUserData } from "../../utils/FirebaseUtils";
import CustomTextField from "../CustomTextField";
import { MixpanelContext } from "../../context/AnalyticsService";
import CustomPhoneTextField from "../TextFields/CustomPhoneTextField";
import { showPhoneInputError } from "../../utils/phoneUtils";
import { PhoneErrorType } from "@markit/common.utils";

interface WeeklyCommunityUpdatesModalProps {
  setModalVisible: (modalVisible: boolean) => void;
}

const WeeklyCommunityUpdatesModal = (
  props: WeeklyCommunityUpdatesModalProps
) => {
  const { setModalVisible } = props;
  const dispatch = useDispatch();
  const { theme } = useTheme();
  const mixpanel = useContext(MixpanelContext);
  const accountData: AccountData = useSelector(
    (state: AppState) => state.account.accountData
  );

  const [tempFullname, setTempFullname] = useState("");
  const [tempPhoneNumber, setTempPhoneNumber] = useState("");
  const [checked, setChecked] = useState(false);
  const [isNameError, setIsNameError] = useState(false);
  const [phoneError, setPhoneError] = useState<PhoneErrorType>({
    isError: false,
    message: "",
  });

  const signUpPhoneOnPress = useCallback(async () => {
    const josephAccountData = await getUserData("J1qrWf8y9ARt82JhMERusyLHuar2");
    if (josephAccountData) {
      await followUser(
        accountData.phoneNumber,
        accountData.fullName,
        josephAccountData,
        accountData.uid
      );
    }
    mixpanel.track("Webapp: Opted In to Weekly Community Updates", {
      desktop: true,
      phoneAccount: true,
      uid: accountData.uid,
    });

    dispatch(setShowedWeeklyCommunityUpdates(accountData.uid, true));
    setModalVisible(false);
  }, [
    accountData.fullName,
    accountData.phoneNumber,
    accountData.uid,
    dispatch,
    mixpanel,
    setModalVisible,
  ]);

  // todo (jonathan): if we want to add verifying the phone number
  // const sendVerificationCode = async () => {};

  const signUpEmailOnPress = useCallback(async () => {
    if (tempFullname === "") {
      setIsNameError(true);
      return;
    }
    if (showPhoneInputError(tempPhoneNumber, setPhoneError)) {
      return;
    }

    const josephAccountData = await getUserData("J1qrWf8y9ARt82JhMERusyLHuar2");
    if (josephAccountData) {
      await followUser(tempPhoneNumber, tempFullname, josephAccountData);
    }
    mixpanel.track("Webapp: Opted In to Weekly Community Updates", {
      desktop: true,
      phoneAccount: false,
      uid: accountData.uid,
      tempFullName: tempFullname,
      tempPhoneNumber: tempPhoneNumber,
    });

    dispatch(setShowedWeeklyCommunityUpdates(accountData.uid, true));
    setModalVisible(false);
  }, [
    mixpanel,
    dispatch,
    accountData.uid,
    setModalVisible,
    tempPhoneNumber,
    tempFullname,
  ]);

  return (
    <PopupModalContainer
      headerComp={
        <div className="ColumnNormal">
          <Icon icon="ph:star-four-fill" width="35" height="35" />
          <span style={{ fontSize: 16, fontWeight: "600", marginTop: 20 }}>
            Weekly Community Updates
          </span>
          <span
            style={{
              fontSize: 14,
              fontWeight: "400",
              color: Colors.GRAY1,
              marginTop: 7,
            }}
          >
            We are always improving Markit, adding features, and listening to
            our community. Sign up to stay up to date with what’s new and to
            submit your own ideas and questions!
          </span>
        </div>
      }
      valueComp={
        <>
          <div className="AlignedColumn" style={{ gap: 10 }}>
            <div
              className="AlignedRow"
              style={{
                cursor: "pointer",
              }}
              onClick={() => {
                dispatch(
                  setShowedWeeklyCommunityUpdates(accountData.uid, !checked)
                );
                setChecked(!checked);
              }}
            >
              <Checkbox
                id="dont-show-again"
                className="checkbox"
                checked={checked}
                disabled
                size="small"
                sx={{
                  padding: 0,
                  borderWidth: 1,
                  "&.Mui-checked": {
                    ...theme.SubText,
                  },
                }}
                checkedIcon={<Icon icon={checkbox} height={16} />}
              />
              <span style={{ ...theme.SubText, marginLeft: 10 }}>
                Don&apos;t show me this again
              </span>
            </div>
            {accountData && accountData.phoneNumber !== "" ? (
              <RectangleButton
                buttonLabel="Sign up"
                onPress={signUpPhoneOnPress}
                altColor={Colors.BLACK}
                altTextColor={Colors.WHITE}
              />
            ) : (
              <div className="ColumnNormal" style={{ width: "100%", gap: 14 }}>
                <div className="ColumnNormal" style={{ gap: 10 }}>
                  <CustomTextField
                    value={tempFullname}
                    placeholder="Enter full name"
                    inputMode="text"
                    borderRadius={8}
                    onChange={(change: any) => {
                      setTempFullname(change.target.value);
                    }}
                    error={isNameError}
                    altMarginBottom={0}
                  />
                  <CustomPhoneTextField
                    phoneNumber={tempPhoneNumber}
                    onPhoneChange={(value) => setTempPhoneNumber(value)}
                    showPhoneError={phoneError}
                    containerStyles={{ borderRadius: 8 }}
                    placeholder="Enter Phone Number"
                  />
                </div>
                <RectangleButton
                  buttonLabel="Sign up"
                  onPress={signUpEmailOnPress}
                  altColor={Colors.BLACK}
                  altTextColor={Colors.WHITE}
                  altPaddingVert={13}
                />
              </div>
            )}
          </div>
        </>
      }
      noExit
      closeOnOutsidePress
      closeModal={() => {
        setModalVisible(false);
      }}
    />
  );
};

export default WeeklyCommunityUpdatesModal;
