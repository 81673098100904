import { memo, useState } from "react";
import markitIcon from "../../src/assets/MarkitLogoBlue.png";
import Footer from "../components/Footer";
import TopHeader from "../components/TopHeader";
import { Colors } from "../utils/colors";
import { DotWave } from "@uiball/loaders";
import { useSelector } from "react-redux";
import { getAccountState } from "../redux/slices/accountSlice";
import CreatorModeTopHeader from "../components/CreatorDashboard/CreatorModeTopHeader";
import { isDesktop } from "react-device-detect";
import { useOnMount } from "../utils/useOnMount";

const LoadingScreen = memo(function LoadingFn({
  error,
  underMaintenance,
  isLoadingPage,
}: {
  error?: boolean;
  underMaintenance?: boolean;
  isLoadingPage?: boolean;
}) {
  const { inCreatorMode } = useSelector(getAccountState).account.accountData;
  const [showLoadingDots, setShowLoadingDots] = useState(false);

  useOnMount(() => {
    // If defined, only show loading dots after three seconds of loading
    // If not defined, show loading dots on start
    if (isLoadingPage === undefined) {
      setShowLoadingDots(true);
    }
    // Show loading dots if loading for longer than 3 seconds
    setTimeout(() => {
      if (isLoadingPage) {
        setShowLoadingDots(true);
      }
    }, 3000);
  });

  return error || underMaintenance ? (
    <div className="ErrorOrMaintenanceContainer">
      <div style={{ backgroundColor: Colors.GRAY6, height: "70vh" }}>
        {inCreatorMode && isDesktop ? <CreatorModeTopHeader /> : <TopHeader />}
        <div style={{ display: "grid", placeItems: "center", height: "80%" }}>
          <div style={{ display: "grid", placeItems: "center" }}>
            <div>
              <img className="LoadingPic" alt="LoadingPic" src={markitIcon} />
            </div>
            {error ? (
              <>
                <div
                  style={{
                    marginBottom: 0,
                    marginBlock: 15,
                    fontWeight: 600,
                    fontSize: 25,
                  }}
                >
                  404 Error
                </div>
                <div
                  style={{
                    textAlign: "center",
                    width: "70%",
                    color: "#929292",
                  }}
                >
                  You might have an invalid link. Please try refreshing this
                  page.
                </div>
              </>
            ) : (
              underMaintenance && (
                <>
                  <div
                    style={{
                      marginBottom: 0,
                      marginBlock: 15,
                      fontWeight: 600,
                      fontSize: 25,
                    }}
                  >
                    Currently Under Maintenance
                  </div>
                  <div
                    style={{
                      textAlign: "center",
                      width: "70%",
                      color: "#929292",
                    }}
                  >
                    The page you're looking for is currently under maintenance
                    and will be back by 5pm EST.
                  </div>
                </>
              )
            )}
          </div>
        </div>
      </div>
      <Footer forceLight />
    </div>
  ) : (
    <div className="ErrorOrMaintenanceContainer">
      {!showLoadingDots ? (
        <div style={{ backgroundColor: Colors.GRAY6, height: "100vh" }} />
      ) : (
        <>
          <div style={{ backgroundColor: Colors.GRAY6, height: "100vh" }}>
            {inCreatorMode && isDesktop ? (
              <CreatorModeTopHeader />
            ) : (
              <TopHeader />
            )}
            <div className="LoadingScreen">
              <DotWave size={47} speed={1} color={Colors.BLACK} />
            </div>
          </div>
          <Footer forceLight />
        </>
      )}
    </div>
  );
});

export default LoadingScreen;
