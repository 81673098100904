import { AccountData, GroupChat, Event, TicketV2 } from "@markit/common.types";
import {
  ActionReducerMapBuilder,
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";
import {
  AsyncLoadingState,
  createAsyncErrorState,
  createAsyncLoadedState,
  createAsyncLoadingState,
  createAsyncNotLoadedState,
} from "../../utils/asyncLoadingState";
import {
  getEventData,
  getGroupData,
  getTicketData,
  getUserData,
} from "../../utils/FirebaseUtils";
import { AppState } from "../store";

/**
 * Stores all the loaded users/events/groups/etc
 */
export interface DataState {
  groupChats: { [groupChatId: string]: AsyncLoadingState<GroupChat> };
  users: { [userId: string]: AsyncLoadingState<AccountData> };
  events: { [eventId: string]: AsyncLoadingState<Event> };
  tickets: { [ticketId: string]: AsyncLoadingState<TicketV2> };
}

export const initialDataState: DataState = {
  groupChats: {},
  users: {},
  events: {},
  tickets: {},
};

function createActionsAndReducerForData<
  T extends keyof DataState,
  J extends DataState[T][string] extends AsyncLoadingState<infer X> ? X : never
>(key: T, queryFn: (id: string) => Promise<J | undefined>) {
  const loadFn = createAsyncThunk(
    `data/${key}`,
    async (id: string, { rejectWithValue }) => {
      if (id === "") {
        return rejectWithValue("cannot load id of empty string");
      }
      try {
        const response = await queryFn(id);
        if (response) {
          // console.log(`loaded ${key} ${id}`, response);
          return response;
        }
        return rejectWithValue(response);
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );

  const buildReducer = (builder: ActionReducerMapBuilder<DataState>) => {
    builder.addCase(loadFn.pending, (state, action) => {
      const objectId = action.meta.arg;
      return {
        ...state,
        [key]: {
          ...state[key],
          [objectId]: createAsyncLoadingState(),
        },
      };
    });
    builder.addCase(loadFn.fulfilled, (state, action) => {
      const objectId = action.meta.arg;
      const object = action.payload;
      if (!objectId) {
        return state;
      }
      return {
        ...state,
        [key]: {
          ...state[key],
          [objectId]: createAsyncLoadedState(object),
        },
      };
    });
    builder.addCase(loadFn.rejected, (state, action) => {
      const objectId = action.meta.arg;
      return {
        ...state,
        [key]: {
          ...state[key],
          [objectId]: createAsyncErrorState(),
        },
      };
    });
  };

  return { loadFn, buildReducer };
}

function createActionsAndReducerForEventData<
  T extends keyof DataState,
  J extends DataState[T][string] extends AsyncLoadingState<infer X> ? X : never
>(
  key: T,
  queryFn: (eventItem: {
    eventId: string;
    itemId: string;
  }) => Promise<J | undefined>
) {
  const loadFn = createAsyncThunk(
    `data/${key}`,
    async (
      { eventId, itemId }: { eventId: string; itemId: string },
      { rejectWithValue }
    ) => {
      if (eventId === "" || itemId === "") {
        return rejectWithValue("cannot load id of empty string");
      }
      try {
        const response = await queryFn({ eventId, itemId });
        if (response) {
          console.log(`loaded ${key} ${itemId}`, response);
          return response;
        }
        return rejectWithValue(response);
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );

  const buildReducer = (builder: ActionReducerMapBuilder<DataState>) => {
    builder.addCase(loadFn.pending, (state, action) => {
      const objectId = action.meta.arg;
      return {
        ...state,
        [key]: {
          ...state[key],
          [objectId.itemId]: createAsyncLoadingState(),
        },
      };
    });
    builder.addCase(loadFn.fulfilled, (state, action) => {
      const objectId = action.meta.arg;
      const object = action.payload;
      if (!objectId) {
        return state;
      }
      return {
        ...state,
        [key]: {
          ...state[key],
          [objectId.itemId]: createAsyncLoadedState(object),
        },
      };
    });
    builder.addCase(loadFn.rejected, (state, action) => {
      const objectId = action.meta.arg;
      return {
        ...state,
        [key]: {
          ...state[key],
          [objectId.itemId]: createAsyncErrorState(),
        },
      };
    });
  };

  return { loadFn, buildReducer };
}

const gatherTicketData = async (ticket: {
  eventId: string;
  itemId: string;
}) => {
  const ticketData = await getTicketData(ticket.eventId, ticket.itemId);
  if (ticketData) {
    return ticketData;
  }
  return undefined;
};

export const { loadFn: loadGroup, buildReducer: buildGroupChatReducer } =
  createActionsAndReducerForData("groupChats", getGroupData);
export const { loadFn: loadUser, buildReducer: buildUserReducer } =
  createActionsAndReducerForData("users", getUserData);
export const { loadFn: loadEvent, buildReducer: buildEventReducer } =
  createActionsAndReducerForData("events", getEventData);
export const { loadFn: loadTicket, buildReducer: buildTicketReducer } =
  createActionsAndReducerForEventData("tickets", gatherTicketData);

export const DataLoaders = {
  user: loadUser,
  group: loadGroup,
  event: loadEvent,
  ticket: loadTicket,
};

export const dataSlice = createSlice({
  name: "data",
  initialState: initialDataState,
  reducers: {},
  extraReducers: (builder) => {
    buildGroupChatReducer(builder);
    buildUserReducer(builder);
    buildEventReducer(builder);
    buildTicketReducer(builder);
  },
});

export const dataReducer = dataSlice.reducer;

// Selectors
export const getLoadedGroupChat = (state: AppState, groupChatId: string) =>
  state.data.groupChats[groupChatId] ?? createAsyncNotLoadedState();
export const getLoadedUser = (state: AppState, userId: string) =>
  state.data.users[userId] ?? createAsyncNotLoadedState();
// TODO (jasonx): Loading by username doesn't have an error state
export const getLoadedUserByUsername = (state: AppState, userName: string) =>
  Object.values(state.data.users).find(
    (user) => user.data?.username === userName
  ) ?? createAsyncErrorState();
export const getLoadedEvent = (state: AppState, eventId: string) =>
  state.data.events[eventId] ?? createAsyncNotLoadedState();
export const getLoadedUsers = (state: AppState, userIds: string[]) =>
  userIds.map((userId) => getLoadedUser(state, userId));
export const getAllLoadedEvents = (state: AppState) =>
  Object.values(state.data.events)
    .map((val) => val.data)
    .filter((event) => event != null) as Event[];
export const getLoadedTicket = (state: AppState, ticketId: string) =>
  state.data.tickets[ticketId] ?? createAsyncNotLoadedState();

export const getDataState = (state: AppState) => state;
