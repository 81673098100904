import { Event } from "@markit/common.types";
import { useSelector } from "react-redux";
import { getAccountState } from "../../../../../redux/slices/accountSlice";
import { useCallback, useMemo, useState } from "react";
import { filter } from "lodash";
import FlatList from "flatlist-react/lib";
import SearchBoxContainer from "../../../../Containers/SearchBoxContainer";
import IntermediaryModalContainer from "../../../../Containers/IntermediaryModalContainer";
import { SelectRecipientGroupType } from "../SelectRecipientsPanel";
import { getEventState } from "../../../../../redux/slices/eventSlice";
import { GenericActionItem } from "../../../../DisplayItem/GenericActionItem";
import { GetLongDate } from "../../../../../utils/GetLongDate";
import { GetTime } from "../../../../../utils/GetTime";
import { HorizontalDivider } from "../../../../Dividers/HorizontalDivider";
import { Icon } from "@iconify/react";
import { Colors } from "../../../../../utils/colors";
import { EmptyStateFlatlist } from "../../../../EmptyStates/EmptyStateFlatlist";
import {
  dateIsCurrentYear,
  hostOnlyEventsAndLinks,
} from "@markit/common.utils";

type SelectRecipientsEventLibraryProps = {
  updateSelectType: (selectType: Partial<SelectRecipientGroupType>) => void;
};

const SelectRecipientsEventLibrary = (
  props: SelectRecipientsEventLibraryProps
) => {
  const { updateSelectType } = props;
  const { accountData } = useSelector(getAccountState).account;
  const { events } = useSelector(getEventState).events;
  const [searchTerm, setSearchTerm] = useState("");

  const userEvents = useMemo(
    () => hostOnlyEventsAndLinks(events, accountData.uid).reverse(),
    [accountData.uid, events]
  );

  const containsEvent = useCallback((event: Event, query: string) => {
    return event.title.toLowerCase().includes(query.toLowerCase());
  }, []);

  const handleSearch = (text: string) => {
    setSearchTerm(text);
  };

  const itemsToShow: Event[] = useMemo(() => {
    let searchResults: any[] = userEvents;
    if (searchTerm !== "") {
      searchResults = filter(userEvents, (event: Event) => {
        return containsEvent(event, searchTerm);
      });
    }
    return searchResults;
  }, [userEvents, searchTerm, containsEvent]);

  return (
    <IntermediaryModalContainer
      body={
        <div className="ColumnNormal">
          <SearchBoxContainer
            value={searchTerm}
            placeholder={"Search Events..."}
            onChange={(e) => {
              handleSearch(e.target.value);
            }}
            containerStyles={{ marginTop: 0, marginBottom: 14 }}
          />

          <FlatList
            list={itemsToShow}
            renderItem={(item: Event) => (
              <>
                <GenericActionItem
                  leftComp={
                    <img
                      alt="EventLibrary"
                      src={item.photoURL}
                      width={48}
                      height={48}
                      style={{ borderRadius: 6, cursor: "pointer" }}
                    />
                  }
                  textComp={
                    <div className="ColumnNormalSelect" style={{ gap: 4 }}>
                      <span className="TextOverflowEllipsis bodyMedium">
                        {item.title}
                      </span>
                      <span className="smallBodySubtext">{`${GetLongDate(
                        item.start,
                        true,
                        true,
                        true,
                        !dateIsCurrentYear(item.start)
                      )}, ${GetTime(item.start)}`}</span>
                    </div>
                  }
                  rightComp={
                    <Icon
                      icon="ion:chevron-right"
                      height={16}
                      color={Colors.GRAY1}
                    />
                  }
                  onPress={() => updateSelectType({ selectedItem: item })}
                />
                <HorizontalDivider altMargin={10} />
              </>
            )}
            renderWhenEmpty={
              <EmptyStateFlatlist
                containerStyles={{ paddingTop: 200 }}
                isLoading={false}
                searchTerm={searchTerm}
                nonSearchEmptyView={<></>}
              />
            }
          />
        </div>
      }
    />
  );
};

export default SelectRecipientsEventLibrary;
