import React, { useState, useCallback } from "react";
import { Colors } from "../../../../utils/colors";
import StandardBorderedContainer from "../../../Containers/StandardBorderedContainer";
import RectangleButton from "../../../Buttons/RectangleButton";
import AddToListModal from "../AudienceLists/AddToListModal";
import { AudienceList } from "@markit/common.types";
import AudienceListItemsContainer from "../AudienceLists/AudienceListItemsContainer";

type SegmentContactsProps = {
  selectedLists: AudienceList[];
  setSelectedLists: (selectedLists: AudienceList[]) => void;
};

const SegmentContacts = (props: SegmentContactsProps) => {
  const { selectedLists, setSelectedLists } = props;
  const [listModalVisible, setListModalVisible] = useState(false);

  const styles = {
    headerText: { fontSize: 20, fontWeight: 500 },
    subtext: { fontSize: 14, color: Colors.GRAY1 },
    bodyMedium: { fontSize: 14, fontWeight: 500 },
  };

  const addListsOnPress = useCallback(
    (lists: AudienceList[]) => {
      setSelectedLists(lists);
    },
    [setSelectedLists]
  );

  return (
    <>
      <div
        className="ColumnNormal"
        style={{ gap: 40, paddingBlock: 40, alignItems: "center" }}
      >
        <div className="ColumnNormal" style={{ gap: 10 }}>
          <span style={{ ...styles.headerText, textAlign: "center" }}>
            Segment uploaded contacts by adding to lists
          </span>
          <span style={styles.subtext}>
            Optionally organize your contacts into existing lists, or create a
            new one.
          </span>
        </div>
        {selectedLists.length === 0 ? (
          <StandardBorderedContainer
            containerStyles={{
              border: "1.5px dashed #b9b9b9",
              paddingInline: 40,
              paddingBlock: 60,
              width: 547,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div>
              <RectangleButton
                buttonLabel="Add to Lists"
                onPress={() => setListModalVisible(true)}
                altColor={Colors.BLACK}
                altTextColor={Colors.WHITE}
                altPaddingHorz={24}
                altPaddingVert={14}
              />
            </div>
          </StandardBorderedContainer>
        ) : (
          <div className="ColumnNormal" style={{ width: 547 }}>
            <AudienceListItemsContainer
              lists={selectedLists}
              itemOnPress={(item: AudienceList) =>
                setSelectedLists(
                  selectedLists.filter((list) => list.id !== item.id)
                )
              }
              newListOnPress={() => setListModalVisible(true)}
            />
          </div>
        )}
      </div>
      {listModalVisible ? (
        <AddToListModal
          isVisible={listModalVisible}
          closeOnPress={() => setListModalVisible(false)}
          addOnPress={(lists: AudienceList[]) => addListsOnPress(lists)}
          preselectedLists={selectedLists}
        />
      ) : null}
    </>
  );
};

export default SegmentContacts;
