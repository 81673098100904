import "../../css/GlobalStyles.css";
import { Helmet } from "react-helmet";
import {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import TopHeader from "../../components/TopHeader";
import { useSelector } from "react-redux";
import {
  LoginState,
  fetchAudienceLists,
  getAccountState,
  toggleInCreatorMode,
} from "../../redux/slices/accountSlice";
import CreatorModeSidebar from "../../components/CreatorDashboard/CreatorModeSidebar";
import CreatorModeTopHeader from "../../components/CreatorDashboard/CreatorModeTopHeader";
import { MixpanelContext } from "../../context/AnalyticsService";
import StandardBorderedContainer from "../../components/Containers/StandardBorderedContainer";
import { Colors } from "../../utils/colors";
import EmptyViewStateMobile from "../../components/EmptyStates/EmptyViewStateMobile";
import { isDesktop } from "react-device-detect";
import { useNavigate } from "../../hooks/useNavigate";
import RectangleButtonCreatorPanel from "../../components/Buttons/RectangleButtonCreatorPanel";
import HelpModal from "../../components/Containers/HelpModal";
import { HorizontalDivider } from "../../components/Dividers/HorizontalDivider";
import RectangleButton from "../../components/Buttons/RectangleButton";
import { Icon } from "@iconify/react";
import UploadContacts from "../../components/CreatorDashboard/CreatorProfile/ImportContacts/UploadContacts";
import ConfirmDeleteModal from "../../components/Containers/ConfirmPopups/ConfirmDeleteModal";
import AssignImportedContacts from "../../components/CreatorDashboard/CreatorProfile/ImportContacts/AssignImportedContacts";
import {
  foundExistingSpreadsheetName,
  getTotalSpreadsheetFollowersUploaded,
} from "../../utils/spreadsheetUtils";
import {
  AudienceList,
  SavedFormQuestion,
  SpreadsheetInfo,
} from "@markit/common.types";
import ConfirmActionModal from "../../components/Containers/ConfirmPopups/ConfirmActionModal";
import SegmentContacts from "../../components/CreatorDashboard/CreatorProfile/ImportContacts/SegmentContacts";
import ConfirmImportContacts from "../../components/CreatorDashboard/CreatorProfile/ImportContacts/ConfirmImportContacts";
import { API } from "../../API";
import { useDispatch } from "react-redux";
import ProgressActionModal, {
  ProgressActionStatus,
} from "../../components/Containers/ProgressActionModal";
import { hasSubscription } from "@markit/common.utils";

export enum ImportContactsStage {
  UPLOAD = 0,
  ASSIGN = 1,
  SEGMENT = 2,
  CONFIRM = 3,
}

const ImportContacts = () => {
  const { accountData, sidebarCondensed, loggedIn, appInitialized } =
    useSelector(getAccountState).account;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const mixpanel = useContext(MixpanelContext);
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [importStage, setImportStage] = useState(ImportContactsStage.UPLOAD);
  const [currNumImported, setCurrNumImported] = useState(0);
  // Upload Stage
  const [spreadsheet, setSpreadsheet] = useState<SpreadsheetInfo>();
  const [displayFile, setDisplayFile] = useState<{
    name: string;
    isError: boolean;
    message: string;
  }>({ name: "", isError: false, message: "" });
  const [permissionsChecked, setPermissionsChecked] = useState(false);
  // Assign Stage
  const [assignedColumns, setAssignedColumns] = useState<
    (SavedFormQuestion | undefined)[]
  >([]);
  // Segment Stage
  const [selectedLists, setSelectedLists] = useState<AudienceList[]>([]);
  const [includeExistingContacts, setIncludeExistingContacts] = useState(false);

  const [importStatusVisible, setImportStatusVisible] =
    useState<ProgressActionStatus>({
      modalVisible: false,
      state: "completed",
    });
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [confirmVisible, setConfirmVisible] = useState(false);
  const [confirmImportVisible, setConfirmImportVisible] = useState(false);

  // States for sidebar
  const refContainer = useRef<HTMLDivElement>(null);

  const [morePanelVisible, setMorePanelVisible] = useState(false);

  const styles = {
    subtext: { fontSize: 14, color: Colors.GRAY1 },
    progressDivider: { width: 28, backgroundColor: Colors.BLACK, height: 1 },
  };

  useEffect(() => {
    if (loggedIn === LoginState.LOGGED_IN && !appInitialized) {
      return;
    }
    // Handle Redirecting
    if (!hasSubscription(accountData)) {
      navigate("/home", { state: { initialTabValue: 2 } });
      return;
    } else if (!accountData.inCreatorMode) {
      dispatch(toggleInCreatorMode(accountData.uid, accountData.inCreatorMode));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountData, appInitialized]);

  useEffect(() => {
    (async () => {
      // Get the number of imported contacts the user has uploaded already
      const numImportedContacts = await getTotalSpreadsheetFollowersUploaded(
        accountData.uid
      );
      setCurrNumImported(numImportedContacts);
    })();
  }, [accountData.uid]);

  // Reset fields if spreadsheet is changed
  useEffect(() => {
    if (assignedColumns) {
      setAssignedColumns([]);
    }
    if (selectedLists) {
      setSelectedLists([]);
    }
    if (includeExistingContacts) {
      setIncludeExistingContacts(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spreadsheet]);

  const numAvailableImports = useMemo(
    () => 500 - currNumImported,
    [currNumImported]
  );

  const numUnassignedColumns = useMemo(
    () => assignedColumns.filter((column) => column === undefined).length,
    [assignedColumns]
  );

  // The spreadsheet rows to show (whether to include existing contacts or not)
  const spreadsheetRows = useMemo(
    () =>
      spreadsheet
        ? includeExistingContacts
          ? spreadsheet.validRows.concat(spreadsheet.existingContactRows)
          : spreadsheet.validRows
        : [],
    [includeExistingContacts, spreadsheet]
  );

  const handleImportOnPress = useCallback(async () => {
    if (spreadsheet) {
      const clearanceApproval =
        spreadsheet.validRows.length <= numAvailableImports ||
        accountData.importContactsApproval;
      setImportStatusVisible({
        modalVisible: true,
        state: "in progress",
      });
      const finalSpreadsheetInfo: SpreadsheetInfo = {
        ...spreadsheet,
        fileName: displayFile.name,
      };
      await API.spreadsheet
        .uploadSpreadsheet({
          uid: accountData.uid,
          spreadsheet: finalSpreadsheetInfo,
          clearanceApproval: clearanceApproval,
          assignedQuestions: assignedColumns,
          selectedListIds: selectedLists.map((list) => list.id),
          includeExistingContacts: includeExistingContacts,
        })
        .then((response) => {
          if (!response.success) {
            setImportStatusVisible({
              modalVisible: true,
              state: "failed",
            });
          }
          if (permissionsChecked) {
            mixpanel.track("Checked Agreement For Import Contacts", {
              distinct_id: accountData.uid,
              clearance_approval: clearanceApproval,
              valid_contacts: spreadsheet.validRows.length,
            });
          }
          if (selectedLists.length > 0) {
            dispatch(fetchAudienceLists(accountData.uid));
          }
          setImportStatusVisible({
            modalVisible: true,
            state: "completed",
          });
        })
        .catch(() => {
          setImportStatusVisible({
            modalVisible: true,
            state: "failed",
          });
        });
    }
  }, [
    accountData.importContactsApproval,
    accountData.uid,
    assignedColumns,
    dispatch,
    displayFile.name,
    includeExistingContacts,
    mixpanel,
    numAvailableImports,
    permissionsChecked,
    selectedLists,
    spreadsheet,
  ]);

  // Add the checks before showing the confirmation import popup
  const continueOnPress = useCallback(async () => {
    const spreadsheetNameExists = await foundExistingSpreadsheetName(
      displayFile.name,
      accountData.uid
    );

    if (importStage === ImportContactsStage.UPLOAD) {
      if (spreadsheetNameExists || displayFile.name === "") {
        setDisplayFile({
          ...displayFile,
          isError: true,
          message:
            displayFile.name === ""
              ? "You must enter a display name."
              : "This display name has been used before. Choose another name.",
        });
        return;
      }
      setImportStage(ImportContactsStage.ASSIGN);
      mixpanel.track("Finished Import Contacts Stage", {
        distinct_id: accountData.uid,
        stage: ImportContactsStage.UPLOAD,
        spreadsheet_name: displayFile.name,
        valid_contacts: spreadsheet?.validRows.length,
      });
    } else if (importStage === ImportContactsStage.ASSIGN) {
      if (numUnassignedColumns > 0) {
        setConfirmVisible(true);
        return;
      }
      setImportStage(ImportContactsStage.SEGMENT);
      mixpanel.track("Finished Import Contacts Stage", {
        distinct_id: accountData.uid,
        stage: ImportContactsStage.ASSIGN,
        assignedQuestionIds: assignedColumns.map((column) =>
          column ? column.id : undefined
        ),
      });
    } else if (importStage === ImportContactsStage.SEGMENT) {
      setImportStage(ImportContactsStage.CONFIRM);
      mixpanel.track("Finished Import Contacts Stage", {
        distinct_id: accountData.uid,
        stage: ImportContactsStage.SEGMENT,
        selectedListIds: selectedLists.map((list) => list.id),
      });
    } else if (importStage === ImportContactsStage.CONFIRM) {
      setConfirmImportVisible(true);
    }
  }, [
    accountData.uid,
    assignedColumns,
    displayFile,
    importStage,
    mixpanel,
    numUnassignedColumns,
    selectedLists,
    spreadsheet,
  ]);

  const navigateImportTab = useCallback(() => {
    navigate("/home", { state: { initialTabValue: 2 } });
  }, [navigate]);

  const backOnPress = useCallback(() => {
    if (importStage === ImportContactsStage.UPLOAD) {
      if (spreadsheet) {
        setShowConfirmDelete(true);
      } else {
        navigateImportTab();
      }
    } else {
      setImportStage(
        importStage === ImportContactsStage.ASSIGN
          ? ImportContactsStage.UPLOAD
          : importStage === ImportContactsStage.SEGMENT
          ? ImportContactsStage.ASSIGN
          : ImportContactsStage.SEGMENT
      );
    }
  }, [importStage, navigateImportTab, spreadsheet]);

  const importProgressItem = useCallback(
    (title: string, stageNum: number) => (
      <>
        {stageNum !== 0 ? (
          <div
            style={{
              ...styles.progressDivider,
              backgroundColor:
                stageNum > importStage ? Colors.GRAY1 : Colors.BLACK,
            }}
          />
        ) : null}
        <StandardBorderedContainer
          containerStyles={{
            borderColor: stageNum === importStage ? Colors.BLACK : Colors.GRAY1,
            paddingInline: 14,
            paddingBlock: 10,
            borderRadius: 100,
          }}
        >
          <div className="AlignedRow" style={{ gap: 7 }}>
            {importStage > stageNum ? (
              <Icon
                icon="ion:checkmark-circle"
                height={18}
                color={Colors.GREEN2}
              />
            ) : null}
            <span
              style={{
                color: stageNum === importStage ? Colors.BLACK : Colors.GRAY1,
              }}
            >
              {title}
            </span>
          </div>
        </StandardBorderedContainer>
      </>
    ),
    [importStage, styles.progressDivider]
  );

  if (!isDesktop) {
    return <EmptyViewStateMobile />;
  }

  return (
    <div>
      <Helmet>
        <title>{"Import Contacts"}</title>
        <meta name="og:title" content={"Import Contacts"} />
        <meta name="og:description" content={"Upload Spreadsheet"} />
      </Helmet>
      <div
        ref={refContainer}
        className="WebApp"
        style={{
          backgroundColor: Colors.WHITE,
          transition: "0.5s",
          minHeight: "100vh",
          justifyContent: "center",
        }}
      >
        {accountData.inCreatorMode ? (
          <CreatorModeSidebar
            morePanelVisible={morePanelVisible}
            setMorePanelVisible={setMorePanelVisible}
          />
        ) : null}
        <div
          style={
            accountData.inCreatorMode
              ? {
                  width: sidebarCondensed ? "calc(100vw - 76px)" : "86vw",
                  marginLeft: sidebarCondensed ? "76px" : "14vw",
                }
              : undefined
          }
        >
          {accountData.inCreatorMode ? <CreatorModeTopHeader /> : <TopHeader />}
          <div className="ColumnNormal" style={{ gap: 24 }}>
            <div className="CreatorPanelContainer">
              <div className="AlignedRowSpaced">
                <h2>Import Contacts</h2>
                <RectangleButtonCreatorPanel
                  title="Need Help?"
                  onPress={() => setShowHelpModal(true)}
                  iconName=""
                  addHover
                />
              </div>
            </div>
            <div
              className="CreatorPanelContainer"
              style={{ backgroundColor: Colors.WHITE1, paddingBottom: 40 }}
            >
              <StandardBorderedContainer
                containerStyles={{
                  width: "100%",
                  backgroundColor: Colors.WHITE,
                  paddingTop: 40,
                }}
              >
                <div className="ColumnNormal">
                  <div className="Centering ColumnNormal">
                    <div
                      className="AlignedRow"
                      style={{ gap: 7, paddingBottom: 10 }}
                    >
                      {importProgressItem("Upload", ImportContactsStage.UPLOAD)}
                      {importProgressItem("Assign", ImportContactsStage.ASSIGN)}
                      {importProgressItem(
                        "Segment",
                        ImportContactsStage.SEGMENT
                      )}
                      {importProgressItem(
                        "Confirm",
                        ImportContactsStage.CONFIRM
                      )}
                    </div>
                    <div
                      style={{
                        overflowY: "auto",
                        overflowX: "hidden",
                        height: "58vh",
                        width: "100%",
                      }}
                    >
                      {importStage === ImportContactsStage.UPLOAD ? (
                        <UploadContacts
                          spreadsheet={spreadsheet}
                          setSpreadsheet={setSpreadsheet}
                          currNumImported={currNumImported}
                          displayFile={displayFile}
                          setDisplayFile={setDisplayFile}
                          permissionsChecked={permissionsChecked}
                          setPermissionsChecked={setPermissionsChecked}
                          includeExistingContacts={includeExistingContacts}
                          setIncludeExistingContacts={
                            setIncludeExistingContacts
                          }
                        />
                      ) : spreadsheet &&
                        importStage === ImportContactsStage.ASSIGN ? (
                        <AssignImportedContacts
                          spreadsheetRows={spreadsheetRows}
                          assignedColumns={assignedColumns}
                          setAssignedColumns={setAssignedColumns}
                        />
                      ) : importStage === ImportContactsStage.SEGMENT ? (
                        <SegmentContacts
                          selectedLists={selectedLists}
                          setSelectedLists={setSelectedLists}
                        />
                      ) : importStage === ImportContactsStage.CONFIRM ? (
                        <ConfirmImportContacts
                          spreadsheet={spreadsheet}
                          setSpreadsheet={setSpreadsheet}
                          displayFile={displayFile}
                          selectedLists={selectedLists}
                          includeExistingContacts={includeExistingContacts}
                        />
                      ) : null}
                    </div>
                  </div>
                  <div className="ColumnNormal">
                    <HorizontalDivider />
                    <div
                      className="AlignedRow"
                      style={{
                        paddingBlock: 24,
                        justifyContent: "center",
                        gap: 14,
                      }}
                    >
                      <div>
                        <RectangleButton
                          buttonLabel={
                            <span style={{ fontSize: 14, fontWeight: 500 }}>
                              {importStage === ImportContactsStage.UPLOAD
                                ? "Exit"
                                : "Back"}
                            </span>
                          }
                          altColor={Colors.GRAY6}
                          altTextColor={Colors.BLACK}
                          altPaddingHorz={24}
                          altPaddingVert={14}
                          onPress={backOnPress}
                          iconLeft={
                            <Icon
                              icon="mdi:chevron-left"
                              height={16}
                              color={Colors.BLACK}
                            />
                          }
                        />
                      </div>
                      <div>
                        <RectangleButton
                          buttonLabel={
                            <span style={{ fontSize: 14, fontWeight: 500 }}>
                              {importStage === ImportContactsStage.CONFIRM
                                ? "Confirm and Import"
                                : importStage === ImportContactsStage.SEGMENT
                                ? selectedLists.length > 0
                                  ? "Add and Continue"
                                  : "Skip to Confirm"
                                : "Continue"}
                            </span>
                          }
                          altColor={Colors.BLACK}
                          altTextColor={Colors.WHITE}
                          disabled={
                            !spreadsheet || spreadsheetRows.length === 0
                          }
                          altPaddingHorz={
                            importStage === ImportContactsStage.SEGMENT
                              ? 20
                              : 40
                          }
                          altPaddingVert={14}
                          onPress={continueOnPress}
                          iconRight={
                            <Icon
                              icon="mdi:chevron-right"
                              height={16}
                              color={
                                !spreadsheet || spreadsheetRows.length === 0
                                  ? Colors.GRAY1
                                  : Colors.WHITE
                              }
                            />
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </StandardBorderedContainer>
            </div>
          </div>
        </div>
      </div>
      <HelpModal showModal={showHelpModal} setShowModal={setShowHelpModal} />
      <ConfirmDeleteModal
        heading="Are you sure want to exit?"
        subtext="Your changes will be discarded."
        deleteButtonText="Exit"
        hideModal={!showConfirmDelete}
        setIsVisible={setShowConfirmDelete}
        deleteOnPress={navigateImportTab}
        icon={<Icon icon="ion:exit" height={40} />}
      />
      <ConfirmActionModal
        heading={"Ready to Import?"}
        subtext={`You are about to import ${spreadsheetRows.length} contacts from ${displayFile.name}. This cannot be undone.`}
        confirmButtonText={"Import"}
        icon={<Icon icon="ion:cloud-upload" height={40} />}
        hideModal={!confirmImportVisible}
        setIsVisible={setConfirmImportVisible}
        confirmOnPress={handleImportOnPress}
      />
      <ConfirmActionModal
        heading={`You have ${numUnassignedColumns} unassigned columns`}
        subtext={
          "Are you sure you want to continue? Unassigned columns will not be included in this import."
        }
        confirmButtonText={"Continue"}
        icon={<Icon icon="ion:warning" height={40} />}
        hideModal={!confirmVisible}
        setIsVisible={setConfirmVisible}
        confirmOnPress={() => setImportStage(ImportContactsStage.SEGMENT)}
      />
      <ProgressActionModal
        actionStatus={importStatusVisible}
        setActionStatus={setImportStatusVisible}
        failureMessage={
          "An error occurred while importing. Try again or contact our hotline for help."
        }
        inProgressMessage={"Your import may take a few minutes."}
        successMessage={`Successfully imported ${
          spreadsheetRows.length
        } contact${spreadsheetRows.length === 1 ? "" : "s"}.`}
        addOnCompletion={() =>
          navigate("/home", { state: { initialTabValue: 2 } })
        }
      />
    </div>
  );
};

export default memo(ImportContacts);
