import {
  DefaultLinkTrackerAttachmentId,
  EventLinkTracker,
  Event,
  LinkMapping,
  OrganizerType,
  TicketV2,
} from "@markit/common.types";
import FlatList from "flatlist-react/lib";
import { Colors } from "../../utils/colors";
import {
  getGoingCapacity,
  hasSubscriptionUnlockedAdvancedData,
  isEventExternalLink,
  isEventTicketsPaid,
} from "@markit/common.utils";
import Skeleton from "react-loading-skeleton";
import { FormatCurrency } from "../../utils/FormatCurrency";
import BlackHoverButton from "../Buttons/BlackHoverButton";
import { Icon } from "@iconify/react";
import { useTheme } from "../../hooks/useTheme";
import { useCallback, useEffect, useMemo, useState } from "react";
import { API } from "../../API";
import { useSelector } from "react-redux";
import { getAccountState } from "../../redux/slices/accountSlice";
import { EventAnalyticsTicketRow } from "./EventAnalyticsTicketRow";
import {
  getRedeemedTicketsDistributed,
  getTotalAssignedOrganizers,
  getTotalRedeemed,
  getTotalTicketsDistributed,
  getTotalTicketsSold,
} from "../../utils/eventUtils/eventUtils";
import StandardBorderedContainer from "../Containers/StandardBorderedContainer";
import { HorizontalDivider } from "../Dividers/HorizontalDivider";
import EmptyStateButton from "../../components/Buttons/EmptyStateButton";
import GridTableHeader from "../Headers/GridTableHeader";
import SearchBoxContainer from "../Containers/SearchBoxContainer";
import filter from "lodash.filter";
import AlertContainer from "../Containers/AlertContainer";
import ConfirmDeleteModal from "../Containers/ConfirmPopups/ConfirmDeleteModal";
import { TrackingLinkItem } from "../DisplayItem/TrackingLinkItem";
import EventDashboardLinkAnalyticsBox from "./EventDashboardLinkAnalyticsBox";
import { LinkCreationConfirmActions } from "../../screens/Event/EventDashboard";
import { MarkitPlusModal } from "../Subscription/MarkitPlusModal";

type AnalyticsPanelProps = {
  event: Event;
  userTicket: TicketV2 | undefined;
  totalScanned: number;
  totalGuests: number;
  totalRevenue: number | null;
  totalLinkViews: number;
  canMassText: boolean;
  dashboardVisibility: number;
  linkTrackers: EventLinkTracker[];
  linkMappings: LinkMapping[];
  setTrackingLinkModalVisible: (isTrackingLinkModalVisible: boolean) => void;
  numTotalLinkTrackers: number;
  linkTrackerId: string | undefined;
  setLinkTrackerId: (linkTrackerId: string | undefined) => void;
  setTrackingLinkModalText: (trackingLinkModalText: string) => void;
  setConfirmPopupCreationType: (
    trackingLinkText: LinkCreationConfirmActions
  ) => void;
};

export const AnalyticsPanel = (props: AnalyticsPanelProps) => {
  const {
    event,
    userTicket,
    totalScanned,
    totalGuests,
    totalLinkViews,
    totalRevenue,
    canMassText,
    dashboardVisibility,
    linkTrackers,
    linkMappings,
    setTrackingLinkModalVisible,
    numTotalLinkTrackers,
    linkTrackerId,
    setLinkTrackerId,
    setTrackingLinkModalText,
    setConfirmPopupCreationType,
  } = props;
  const { theme } = useTheme();
  const { account } = useSelector(getAccountState);
  const { accountData } = account;
  const [markitPlusModalVisible, setMarkitPlusModalVisible] = useState(false);
  const [loading, setLoading] = useState(true);

  const [searchTerm, setSearchTerm] = useState("");
  const [totalRedeemed, setTotalRedeemed] = useState<number>(0);
  const [ticketsCapacity, setTicketsCapacity] = useState(0);

  const [ticketsSoldNotFromHost, setTicketsSoldNotFromHost] = useState(0);
  const [hostTicketsRedeemed, setHostTicketsRedeemed] = useState(0);
  const [hostTicketsDistributed, setHostTicketsDistributed] = useState(0);

  const [alertText, setAlertText] = useState("");
  const [deleteLinkConfirm, setDeleteLinkConfirm] = useState(false);

  const styles = {
    analyticsContent: {
      gap: 24,
      color: Colors.BLACK,
    },

    analyticsText: {
      fontSize: 32,
    },

    containerView: {
      paddingInline: 14,
    },

    contentView: {
      paddingBottom: 14,
    },

    headerTitle: {
      paddingTop: 14,
      fontSize: 16,
      fontWeight: 600,
    },

    overallContainerView: {
      width: "100%",
      backgroundColor: theme.SecondaryBG.backgroundColor,
      borderRadius: 20,
    },

    sectionHeaderText: {
      marginBottom: 14,
      fontSize: 16,
      fontWeight: 600,
      whiteSpace: "nowrap",
    },

    subText: {
      color: theme.SubText.color,
      fontSize: 12,
    },

    trackingLinkView: {
      marginTop: 24,
      maxHeight: 770,
    },
  };

  const isHost = useMemo(
    () =>
      (userTicket && userTicket.role.type === OrganizerType.HOST) ||
      accountData.isAdmin,
    [accountData.isAdmin, userTicket]
  );

  const handleSearch = (text: string) => {
    setSearchTerm(text.toLowerCase());
  };

  const contains = useCallback((item: EventLinkTracker, query: string) => {
    return item.alias.toLowerCase().includes(query);
  }, []);

  const trackingLinksToShow = useMemo(() => {
    if (searchTerm === "") {
      return linkTrackers;
    } else {
      return filter(linkTrackers, (tracker: EventLinkTracker) => {
        return contains(tracker, searchTerm);
      });
    }
  }, [contains, linkTrackers, searchTerm]);

  const handleDropdownTrackingLinkMenuClose = useCallback(() => {
    setTrackingLinkModalText("");
    setLinkTrackerId(undefined);
  }, [setTrackingLinkModalText, setLinkTrackerId]);

  const copyTrackingLink = useCallback(
    (item: EventLinkTracker) => {
      const linkMapping = linkMappings.find(
        (mapping) => mapping.refId === item.id
      );
      if (linkMapping) {
        const link = `https://markitai.com/e/${event.id}?l=${linkMapping.id}`;

        navigator.clipboard
          .writeText(link)
          .then(() => {
            setAlertText("Copied tracking link to clipboard.");
          })
          .catch((err) => {
            setAlertText(err);
          });
        handleDropdownTrackingLinkMenuClose();
      }
    },
    [linkMappings, event.id, handleDropdownTrackingLinkMenuClose]
  );

  const editTrackingLink = useCallback(
    (item: EventLinkTracker) => {
      setTrackingLinkModalVisible(true);
      setTrackingLinkModalText(item.alias);
      setLinkTrackerId(item.id);
    },
    [setLinkTrackerId, setTrackingLinkModalText, setTrackingLinkModalVisible]
  );

  const deleteTrackingLink = useCallback(async () => {
    if (linkTrackerId) {
      await API.tracking
        .deleteEventLinkTracker({
          userId: event.createdBy,
          eventId: event.id,
          linkTrackerId: linkTrackerId,
        })
        .then(() => {
          handleDropdownTrackingLinkMenuClose();
        });
      setConfirmPopupCreationType(LinkCreationConfirmActions.LINK_DELETION);
    }
  }, [
    event.createdBy,
    event.id,
    handleDropdownTrackingLinkMenuClose,
    linkTrackerId,
    setConfirmPopupCreationType,
  ]);

  const displayTicketsAvailable = useMemo(
    () => getGoingCapacity(event) !== 0,
    [event]
  );

  const newTrackingLinkOnPress = useCallback(() => {
    if (
      !hasSubscriptionUnlockedAdvancedData(accountData) &&
      linkTrackers.length >= 3
    ) {
      setMarkitPlusModalVisible(true);
    } else {
      setTrackingLinkModalVisible(true);
    }
  }, [accountData, linkTrackers.length, setTrackingLinkModalVisible]);

  const closeModal = useCallback(() => {
    setMarkitPlusModalVisible(false);
  }, []);

  // TODO: Create a custom hook wrapper that can load all the required data, so we don't need to pass it as props throughout
  useEffect(() => {
    (async () => {
      if (!event) {
        return;
      }

      const [
        totalRedeemed,
        totalAssignedOrganizers,
        ticketsSoldNotFromHost,
        ticketsCapacity,
        hostTicketsRedeemed,
        hostTicketsDistributed,
      ] = await Promise.all([
        getTotalRedeemed(event.id),
        getTotalAssignedOrganizers(event.id),
        getTotalTicketsSold(event.id),
        getGoingCapacity(event),
        getRedeemedTicketsDistributed(event.id),
        getTotalTicketsDistributed(event.id),
      ]);
      setTotalRedeemed(Math.max(totalRedeemed - totalAssignedOrganizers, 0));
      setTicketsSoldNotFromHost(ticketsSoldNotFromHost);
      setTicketsCapacity(ticketsCapacity);
      setHostTicketsRedeemed(hostTicketsRedeemed);
      setHostTicketsDistributed(hostTicketsDistributed);
      setLoading(false);
    })();
  }, [event]);

  return (
    <div className="RowNormal" style={{ gap: 30 }}>
      <div className="ColumnNormal" style={{ flex: 1 }}>
        {isEventExternalLink(event.eventType) ? (
          <EventDashboardLinkAnalyticsBox
            totalLinkViews={totalLinkViews}
            totalSubmissions={totalGuests}
          />
        ) : (
          <>
            <div style={styles.sectionHeaderText}>Analytics</div>
            <StandardBorderedContainer
              containerStyles={styles.overallContainerView}
            >
              <div className="ColumnNormal">
                <div style={{ ...styles.headerTitle, ...styles.containerView }}>
                  Ticketing
                </div>
                <HorizontalDivider altMargin={14} />
                <div style={{ ...styles.contentView, ...styles.containerView }}>
                  <div className="RowNormal" style={styles.analyticsContent}>
                    <div>
                      <div style={styles.analyticsText}>
                        {totalScanned === null || totalGuests === null ? (
                          <Skeleton />
                        ) : (
                          totalScanned + "/" + totalGuests
                        )}
                      </div>
                      <div style={styles.subText}>Tickets scanned</div>
                    </div>
                    {dashboardVisibility >= 2 && isEventTicketsPaid(event) ? (
                      <>
                        <div>
                          <div style={styles.analyticsText}>
                            {totalRevenue === null ? (
                              <Skeleton />
                            ) : (
                              FormatCurrency(totalRevenue)
                            )}
                          </div>
                          <div style={styles.subText}>Ticket revenue</div>
                        </div>
                      </>
                    ) : null}
                    <div>
                      <div style={styles.analyticsText}>
                        {loading ? <Skeleton /> : totalLinkViews}
                      </div>
                      <div style={styles.subText}>Link Views</div>
                    </div>
                  </div>
                  {dashboardVisibility >= 2 ? (
                    <>
                      <hr />
                      <div style={{ color: Colors.BLACK }}>
                        <div
                          style={{
                            color: Colors.BLACK,
                            fontSize: "14px",
                            fontWeight: "600",
                            marginBottom: "14px",
                          }}
                        >
                          Ticket Breakdown
                        </div>
                        <div style={{ display: "flex" }}>
                          <div
                            style={{
                              fontSize: "24px",
                              marginRight: "14px",
                              minWidth: 45,
                            }}
                          >
                            {totalGuests}
                          </div>
                          <div>
                            <div
                              style={{ fontSize: "12px", marginBottom: "4px" }}
                            >
                              Total Tickets
                            </div>
                            <div
                              style={{
                                color: Colors.GRAY1,
                                fontSize: "12px",
                                marginBottom: "4px",
                              }}
                            >
                              Sold: {ticketsSoldNotFromHost}
                              {displayTicketsAvailable
                                ? "/" + ticketsCapacity
                                : ""}
                            </div>
                            <div
                              style={{ color: Colors.GRAY1, fontSize: "12px" }}
                            >
                              Free Tickets Redeemed: {hostTicketsRedeemed}/
                              {hostTicketsDistributed}
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr />
                      {event.customTickets.length > 1 && (
                        <>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "14px",
                            }}
                          >
                            {event.customTickets.map((ticket) => {
                              return (
                                <EventAnalyticsTicketRow
                                  key={ticket.id}
                                  eventId={event.id}
                                  customTicket={ticket}
                                />
                              );
                            })}
                          </div>
                          <hr />
                        </>
                      )}
                      <div style={{ color: Colors.BLACK }}>
                        <div
                          style={{
                            fontSize: "14px",
                            fontWeight: "600",
                            marginBottom: "14px",
                          }}
                        >
                          Event Audience
                        </div>

                        <div style={{ display: "flex" }}>
                          <div
                            style={{
                              fontSize: "24px",
                              marginRight: "14px",
                              minWidth: 45,
                            }}
                          >
                            {loading ? <Skeleton /> : totalRedeemed}
                          </div>
                          <div>
                            <div
                              style={{ fontSize: "12px", marginBottom: "4px" }}
                            >
                              Phone Numbers Captured
                            </div>
                            <div
                              style={{ color: Colors.GRAY1, fontSize: "12px" }}
                            >
                              You can reach {totalRedeemed && totalRedeemed} of
                              your {totalGuests && totalGuests} attendees when
                              you send a text
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
            </StandardBorderedContainer>
            {isHost && isEventTicketsPaid(event) ? (
              <StandardBorderedContainer
                containerStyles={{
                  ...styles.overallContainerView,
                  marginTop: 24,
                }}
              >
                <div style={{ color: Colors.BLACK, padding: 14 }}>
                  <div
                    style={{
                      fontSize: 14,
                      fontWeight: 600,
                      marginBottom: 4,
                    }}
                  >
                    Manage Promoters
                  </div>
                  <div style={styles.subText}>Available on the Markit App</div>
                </div>
              </StandardBorderedContainer>
            ) : null}
          </>
        )}
      </div>
      {canMassText ? (
        <div className="ColumnNormal" style={{ flex: 1 }}>
          <div style={styles.sectionHeaderText}>Tracking Links</div>
          <StandardBorderedContainer
            containerStyles={styles.overallContainerView}
          >
            <div className="ColumnNormal" style={{ minHeight: 770 }}>
              <div
                className="AlignedRow"
                style={{ ...styles.containerView, paddingBlock: 14, gap: 12 }}
              >
                <SearchBoxContainer
                  placeholder="Search Tracking Links..."
                  onChange={(e) => handleSearch(e.target.value)}
                  containerStyles={{ marginTop: 0 }}
                />
                <BlackHoverButton
                  title={"New Link"}
                  iconName="ion:add-circle"
                  iconSize={19}
                  onPress={newTrackingLinkOnPress}
                  permanentHover
                  containerStyles={{
                    paddingInline: 14,
                    paddingBlock: 12,
                    whiteSpace: "nowrap",
                  }}
                />
              </div>
              <HorizontalDivider />
              <GridTableHeader
                flexSections={[1, 1, 1]}
                sectionTitles={["Name", "Opens", "Conversions"]}
              />
              <HorizontalDivider />
              <div
                className="HideScrollbar"
                style={{
                  ...styles.containerView,
                  ...styles.trackingLinkView,
                  overflowY: "scroll",
                }}
              >
                <FlatList
                  list={trackingLinksToShow}
                  scrollEnabled={false}
                  keyExtractor={(item: EventLinkTracker) => item.id}
                  renderItem={(item) => {
                    const itemTrackingOverview =
                      item.trackingOverviews[DefaultLinkTrackerAttachmentId];

                    return (
                      <TrackingLinkItem
                        item={item}
                        itemTrackingOverview={itemTrackingOverview}
                        numTotalLinkTrackers={numTotalLinkTrackers}
                        copyTrackingLink={() => copyTrackingLink(item)}
                        editTrackingLink={() => editTrackingLink(item)}
                        deleteTrackingLink={() => {
                          setDeleteLinkConfirm(true);
                          setLinkTrackerId(item.id);
                        }}
                      />
                    );
                  }}
                  renderWhenEmpty={() =>
                    linkTrackers.length === 0 ? (
                      <EmptyStateButton
                        title={"No Tracking Links"}
                        btnText="New Tracking Link"
                        description={
                          "Gain insights into opens and conversions of your different promotion channels"
                        }
                        icon={
                          <Icon
                            icon={"ion:analytics"}
                            height={49}
                            style={{ color: Colors.GRAY1 }}
                          />
                        }
                        iconBox={73}
                        onPress={newTrackingLinkOnPress}
                        containerStyles={{ paddingTop: 120 }}
                      />
                    ) : trackingLinksToShow.length === 0 ? (
                      <EmptyStateButton
                        title="No Results"
                        description="Check for typos or search for something else"
                        icon={
                          <Icon
                            icon="ion:search"
                            height={40}
                            color={Colors.GRAY1}
                          />
                        }
                        btnText={undefined}
                        onPress={() => {}}
                        containerStyles={{ paddingTop: 120 }}
                      />
                    ) : null
                  }
                />
              </div>
            </div>
          </StandardBorderedContainer>
        </div>
      ) : null}
      {markitPlusModalVisible ? (
        <MarkitPlusModal closeModal={closeModal} />
      ) : null}
      <AlertContainer
        headerComp={alertText}
        theme={theme}
        closeModal={() => setAlertText("")}
        hideModal={alertText === ""}
      />
      <ConfirmDeleteModal
        heading="Are you sure you wish to delete this tracking link?"
        deleteButtonText="Delete"
        hideModal={!deleteLinkConfirm}
        setIsVisible={setDeleteLinkConfirm}
        deleteOnPress={deleteTrackingLink}
        theme={theme}
      />
    </div>
  );
};
