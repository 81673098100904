import {
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signInWithCredential,
  signInWithPhoneNumber,
  signOut,
  PhoneAuthProvider,
  EmailAuthProvider,
  updateEmail,
  updatePassword,
  sendEmailVerification,
  unlink,
  RecaptchaVerifier,
  linkWithCredential,
  reauthenticateWithCredential,
  sendPasswordResetEmail,
  updatePhoneNumber,
} from "firebase/auth";
import { initializeApp } from "firebase/app";
import {
  getStorage,
  ref,
  getDownloadURL,
  uploadBytes,
  listAll,
} from "firebase/storage";
import {
  arrayUnion,
  arrayRemove,
  doc,
  getFirestore,
  collection,
  getDoc,
  getDocs,
  setDoc,
  updateDoc,
  deleteDoc,
  onSnapshot,
  query,
  where,
  orderBy,
  limit,
  startAfter,
  CollectionReference,
  DocumentSnapshot,
  DocumentReference,
  FieldValue,
  WriteBatch,
  writeBatch,
  getCountFromServer,
} from "firebase/firestore";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";

export const firebaseConfig = {
  apiKey: "AIzaSyBflgt4mVewOljVwVZYkOkLcWyUXC9zggA",
  authDomain: "markit-d5e9b.firebaseapp.com",
  databaseURL: "https://markit-d5e9b.firebaseio.com",
  projectId: "markit-d5e9b",
  storageBucket: "markit-d5e9b.appspot.com",
  messagingSenderId: "268916396786",
  appId: "1:268916396786:ios:59514d8ff8e1aacbf4b66b",
  measurementId: "G-8E7W38QDMB",
};

const firebase = initializeApp(firebaseConfig);

// Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
// key is the counterpart to the secret key you set in the Firebase console.
// Refer to this linear ticket about trying to add this back in: https://linear.app/markit/issue/MARK-2805/get-firebase-app-check-working-with-webapp
// initializeAppCheck(firebase, {
//   provider: new ReCaptchaV3Provider("6LdyyDofAAAAAMnYa-KzDsVnOV0rGB-MoSWbQsf4"),

//   // Don't think we need this anymore.  Was causing tokens to refresh on every screen
//   // Optional argument. If true, the SDK automatically refreshes App Check
//   // tokens as needed.
//   isTokenAutoRefreshEnabled: true,
// });

const firebaseAuth = getAuth(firebase);
getAuth(firebase).useDeviceLanguage();

const db = getFirestore(firebase);
const firebaseStorage = getStorage(firebase);

const firebaseMapsApiKey = "AIzaSyDhyxKp_OvPO9Gj16ZOE4D91NeI-EXWhec";

export {
  firebase,
  firebaseAuth,
  db,
  firebaseStorage,
  firebaseMapsApiKey,
  ref,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signInWithCredential,
  signInWithPhoneNumber,
  updateEmail,
  updatePassword,
  updatePhoneNumber,
  sendEmailVerification,
  unlink,
  linkWithCredential,
  reauthenticateWithCredential,
  sendPasswordResetEmail,
  signOut,
  arrayUnion,
  arrayRemove,
  collection,
  doc,
  getDoc,
  getDocs,
  setDoc,
  updateDoc,
  deleteDoc,
  onSnapshot,
  query,
  where,
  orderBy,
  limit,
  startAfter,
  writeBatch,
  getCountFromServer,
  CollectionReference,
  DocumentSnapshot,
  DocumentReference,
  FieldValue,
  WriteBatch,
  PhoneAuthProvider,
  EmailAuthProvider,
  RecaptchaVerifier,
  getDownloadURL,
  uploadBytes,
  listAll,
};
