import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import PopupModalContainer from "../Containers/PopupModalContainer";
import { Icon } from "@iconify/react";
import { Colors } from "../../utils/colors";
import {
  getAccountState,
  setShowedCategoriesEducationModal,
} from "../../redux/slices/accountSlice";
import RectangleButton from "../Buttons/RectangleButton";
import { useSelector } from "react-redux";

type TextCategoriesTipsModalProps = {
  setModalVisible: (modalVisible: boolean) => void;
};

export const TextCategoriesTipsModal = (
  props: TextCategoriesTipsModalProps
) => {
  const { setModalVisible } = props;
  const dispatch = useDispatch();
  const { uid, showedEducationModals } =
    useSelector(getAccountState).account.accountData;

  const tipItems = [
    {
      title: "Categories are Dynamic Selections",
      description:
        "Categories are great when scheduling a text to a group of people that may change in size with future RSVPs.",
      icon: <Icon icon={"ion:flash"} height={36} color={Colors.PURPLE7} />,
    },
    {
      title: "Selecting Categories & Individuals?",
      description:
        "You cannot combine a selection of categories and individuals.",
      icon: (
        <Icon icon={"ion:close-circle"} height={36} color={Colors.PURPLE7} />
      ),
    },
    {
      title: "Flatten Categories Anytime",
      description:
        "You can flatten dynamic categories into static lists of individual people at anytime.",
      icon: <Icon icon={"ion:albums"} height={36} color={Colors.PURPLE7} />,
    },
  ];

  const doneOnPress = useCallback(() => {
    if (!showedEducationModals.textCategoriesModal) {
      dispatch(setShowedCategoriesEducationModal(uid, true));
    }
    setModalVisible(false);
  }, [
    dispatch,
    setModalVisible,
    showedEducationModals.textCategoriesModal,
    uid,
  ]);

  return (
    <PopupModalContainer
      headerComp={<></>}
      noDivider
      valueComp={
        <div className="AlignedColumn" style={{ gap: 56, paddingTop: 40 }}>
          <span
            style={{
              fontSize: 24,
              fontWeight: 500,
              textAlign: "center",
            }}
          >
            Texting Categories
          </span>
          <div className="ColumnNormal" style={{ paddingInline: 14 }}>
            {tipItems.map((item, index) => (
              <div
                className="AlignedRow"
                style={{
                  gap: 24,
                  paddingBottom: index !== tipItems.length - 1 ? 42 : 0,
                }}
              >
                <div>{item.icon}</div>
                <div className="ColumnNormal" style={{ gap: 4 }}>
                  <span className="sectionTitle">{item.title}</span>
                  <span className="bodySubtext">{item.description}</span>
                </div>
              </div>
            ))}
          </div>
          <RectangleButton
            buttonLabel="Got It"
            onPress={doneOnPress}
            altColor={Colors.BLACK}
            altTextColor={Colors.WHITE}
            altPaddingVert={14}
          />
        </div>
      }
      noExit
      closeOnOutsidePress
      closeModal={() => {
        setModalVisible(false);
      }}
    />
  );
};

export default TextCategoriesTipsModal;
