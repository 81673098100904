import React, {
  CSSProperties,
  forwardRef,
  memo,
  useCallback,
  useState,
} from "react";
import { DefaultInputComponentProps } from "react-phone-number-input";
import { useTheme } from "../../hooks/useTheme";
import { Colors } from "../../utils/colors";
import { PhoneErrorType } from "@markit/common.utils";

interface CustomInputProps extends DefaultInputComponentProps {
  error: PhoneErrorType;
  disabled?: boolean;
  inputStyles?: CSSProperties;
  endAdornment?: JSX.Element;
}

const CustomInput = forwardRef<HTMLInputElement, CustomInputProps>(
  ({ value, onChange, ...rest }, ref) => {
    const { error, disabled, inputStyles, endAdornment } = rest;
    const { theme } = useTheme();
    const [isFocused, setIsFocused] = useState(false);

    const handleFocus = () => {
      setIsFocused(true);
    };

    const handleBlur = () => {
      setIsFocused(false);
    };

    const validatePhoneNumber = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        const phoneNumber = e.target.value;
        onChange(phoneNumber);
      },
      [onChange]
    );

    return (
      <div className="ColumnNormal" style={{ flex: 1, gap: 4 }}>
        <div className="AlignedRow" style={{ position: "relative" }}>
          <style>
            {`input:-webkit-autofill,
              input:-webkit-autofill:hover, 
              input:-webkit-autofill:focus {
              -webkit-text-fill-color: ${theme.PrimaryText.color};
              -webkit-box-shadow: 0 0 0px 1000px ${theme.SecondaryBG.backgroundColor} inset;
            }`}
          </style>
          <input
            {...rest}
            ref={ref}
            value={value}
            onChange={validatePhoneNumber}
            onFocus={handleFocus}
            onBlur={handleBlur}
            autoComplete="off"
            disabled={disabled}
            style={{
              backgroundColor: theme.SecondaryBG.backgroundColor,
              padding: "10px 14px",
              borderRadius: 12,
              border: "1px solid",
              borderColor: error.isError
                ? theme.ErrorBorderColor.borderColor
                : isFocused
                ? theme.FocusedTextFieldBorder.borderColor
                : disabled
                ? theme.TextFieldBorder.borderColor
                : theme.EditableTextFieldBorder.borderColor,
              caretColor: theme.PrimaryText.color,
              color: disabled
                ? theme.DisabledTextFieldText.color
                : theme.PrimaryText.color,
              fontSize: 14,
              flex: 1,
              ...inputStyles,
            }}
          />
          {endAdornment ? (
            <div style={{ position: "absolute", right: 14 }}>
              {endAdornment}
            </div>
          ) : null}
        </div>
        {error.isError ? (
          <span style={{ color: Colors.RED3, fontSize: 12 }}>
            {error.message}
          </span>
        ) : null}
      </div>
    );
  }
);

export default memo(CustomInput);
