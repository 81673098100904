import {
  COMPLIANCE_MESSAGE_DEFAULT,
  contactCardDefault,
  hasSubscription,
} from "@markit/common.utils";
import { Colors } from "../utils/colors";
import { Icon } from "@iconify/react";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { getAccountState } from "../redux/slices/accountSlice";

type ComplianceMessageTagProps = {
  alternateMessage?: string;
};

const ComplianceMessageTag = (props: ComplianceMessageTagProps) => {
  const { alternateMessage } = props;
  const [showDialog, setShowDialog] = useState(false);
  const { accountData } = useSelector(getAccountState).account;

  const containerRef = useRef<any>(null);
  useEffect(() => {
    const handler = (event: any) => {
      if (
        showDialog &&
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setShowDialog(false);
      }
    };
    document.addEventListener("mousedown", handler);
    document.addEventListener("touchstart", handler);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("touchstart", handler);
    };
  }, [showDialog]);

  return (
    <div ref={containerRef} style={{ position: "relative" }}>
      <div style={{ flexWrap: "wrap", color: Colors.GRAY1 }}>
        <span style={{ fontSize: 14 }}>
          {hasSubscription(accountData)
            ? `${contactCardDefault(accountData.fullName)}. `
            : ""}
          {COMPLIANCE_MESSAGE_DEFAULT}
        </span>
        <Icon
          icon="mdi:information-outline"
          onClick={() => setShowDialog(!showDialog)}
          height={16}
          style={{
            marginLeft: 4,
            marginBottom: -3,
            cursor: "pointer",
          }}
        />
      </div>
      {showDialog ? (
        <div
          className="ColumnNormal"
          style={{
            boxShadow: "-1px 1px 4px 0px #1E1E371A",
            backgroundColor: Colors.WHITE,
            zIndex: 99,
            gap: 5,
            width: 400,
            padding: 14,
            borderRadius: 8,
            position: "absolute",
            ...{
              left: "5%",
              top: hasSubscription(accountData) ? 45 : 30,
            },
          }}
        >
          <span style={{ fontSize: 14 }}>Compliance Messaging Included</span>
          <span style={{ fontSize: 12, color: Colors.GRAY2 }}>
            {alternateMessage ??
              "If this is the first text someone has been sent, this compliance message must be included."}
          </span>
        </div>
      ) : null}
    </div>
  );
};

export default ComplianceMessageTag;
