import React from "react";
import { Colors } from "../../../../utils/colors";
import UploadSpreadsheetFile from "./UploadSpreadsheetFile";
import {
  AudienceList,
  SpreadsheetFileDisplay,
  SpreadsheetInfo,
} from "@markit/common.types";
import AudienceListItemsContainer from "../AudienceLists/AudienceListItemsContainer";

type ConfirmImportContactsProps = {
  spreadsheet: SpreadsheetInfo | undefined;
  setSpreadsheet: (spreadsheet: SpreadsheetInfo | undefined) => void;
  displayFile: SpreadsheetFileDisplay;
  selectedLists: AudienceList[];
  includeExistingContacts: boolean;
};

const ConfirmImportContacts = (props: ConfirmImportContactsProps) => {
  const {
    spreadsheet,
    setSpreadsheet,
    displayFile,
    selectedLists,
    includeExistingContacts,
  } = props;

  const styles = {
    headerText: { fontSize: 20, fontWeight: 500 },
    subtext: { fontSize: 14, color: Colors.GRAY1 },
    bodyMedium: { fontSize: 14, fontWeight: 500 },
  };

  return (
    <div className="ColumnNormal" style={{ paddingBottom: 40 }}>
      <div
        className="ColumnNormal"
        style={{
          gap: 10,
          paddingTop: 30,
          paddingBottom: 40,
          textAlign: "center",
        }}
      >
        <span style={{ ...styles.headerText }}>
          Confirm your import details
        </span>
        <span style={styles.subtext}>
          {spreadsheet?.validRows.length} new valid contacts will be added to
          your audience.
          {includeExistingContacts ? (
            <span>
              <br />
              {spreadsheet?.existingContactRows.length} existing contacts will
              be updated.
            </span>
          ) : null}
        </span>
      </div>
      <div className="ColumnNormal Centering" style={{ gap: 24 }}>
        <UploadSpreadsheetFile
          spreadsheet={spreadsheet}
          setSpreadsheet={setSpreadsheet}
          displayFile={displayFile}
          currNumImported={0} // doesnt matter here
        />
        {selectedLists.length > 0 ? (
          <AudienceListItemsContainer lists={selectedLists} />
        ) : null}
      </div>
    </div>
  );
};

export default ConfirmImportContacts;
