import { SupportedCountries } from "@markit/common.utils";
import {
  formatPhoneNumberIntl,
  isValidPhoneNumber,
  parsePhoneNumber,
} from "react-phone-number-input";

// Clean up the formatting of the phone number to display to the user
export const formatPhoneNumber = (phoneNumber: string): string => {
  if (!phoneNumber) {
    return "";
  }
  return formatPhoneNumberIntl(phoneNumber) || phoneNumber;
};

// Formats the number from the imported contacts properly for account creation
export const formatImportedPhoneNumber = (phoneNumber: string): string => {
  if (!phoneNumber) {
    return "";
  }
  // Remove all non-digit characters
  const cleaned = phoneNumber.replace(/\D/g, "");
  const parsedNumber =
    parsePhoneNumber(cleaned) ??
    parsePhoneNumber("+1" + cleaned) ??
    parsePhoneNumber("+" + cleaned);
  const isUSNumber =
    parsedNumber &&
    (parsedNumber.country === "US" || parsedNumber.country === "CA");
  if (isUSNumber) {
    return parsedNumber.number;
  } else {
    const internationalNumber =
      parsePhoneNumber(cleaned) ?? parsePhoneNumber("+" + cleaned);
    return internationalNumber ? internationalNumber.number : cleaned;
  }
};

// Uses react phone number input to check if the number is valid, and not empty
// Also checks if within a valid supported country
export const isPhoneNumberValid = (phoneNumber: string): boolean => {
  if (!phoneNumber) {
    return false;
  }
  const parsedPhoneNumber = parsePhoneNumber(phoneNumber);
  if (
    parsedPhoneNumber?.country &&
    !SupportedCountries.includes(parsedPhoneNumber.country)
  ) {
    return false;
  }
  return isValidPhoneNumber(phoneNumber);
};
