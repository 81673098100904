import "../../css/FullEvent/FullEvent.css";
import "../../css/FullEvent/GuestList.css";
import React, { memo, useState } from "react";
import { Event, AccountData } from "@markit/common.types";
import {
  getTotalAcceptedAttendeeTickets,
  getVisibleGuestList,
} from "../../utils/eventUtils/eventUtils";
import { useOnMount } from "../../utils/useOnMount";
import { IoIosArrowForward } from "react-icons/io";
import { GuestList } from "../../screens/Event/GuestList";
import { useTheme } from "../../hooks/useTheme";
import { Colors } from "../../utils/colors";
import { useSelector } from "react-redux";
import { LoginState, getAccountState } from "../../redux/slices/accountSlice";
import { Icon } from "@iconify/react";
import ProfileSubItemsDisplay from "../DisplayItem/ProfileSubItemsDisplay";
import { userCurrentNumberOfTickets } from "../../utils/eventUtils/userTicketUtils";
import { useAccountSelector } from "../../hooks/useParametricSelector";
import { isDesktop } from "react-device-detect";

type GuestListSummaryProps = {
  event: Event;
};

export const GuestListSummary = memo(function GuestListSummaryFn(
  props: GuestListSummaryProps
) {
  const { event } = props;
  const { accountData, loggedIn } = useSelector(getAccountState).account;
  const { theme } = useTheme();
  const userId = useAccountSelector();
  const [displayGuestList, setDisplayGuestList] = useState<boolean>(false);
  const [eventGuestAccountData, setEventGuestAccountData] = useState<
    AccountData[]
  >([]);
  const [numTotalGuests, setNumTotalGuests] = useState(0);
  const [numUserTickets, setNumUserTickets] = useState(0);

  useOnMount(() => {
    (async () => {
      const [guestList, totalNumGuests, userNumTickets] = await Promise.all([
        getVisibleGuestList(event.id, 7),
        getTotalAcceptedAttendeeTickets(event.id),
        loggedIn === LoginState.LOGGED_IN || userId
          ? userCurrentNumberOfTickets(event.id, userId || accountData.uid)
          : 0,
      ]);
      setEventGuestAccountData(guestList);
      setNumTotalGuests(totalNumGuests);
      setNumUserTickets(userNumTickets);
    })();
  });

  return !event.hideResponses ? (
    <div
      className={!isDesktop && !userId ? "Page" : "PageDesktop"}
      style={theme.SecondaryBG}
    >
      <div className="Container">
        <div className="ResponseHeader">
          <h3 className="AboutSubtitle" style={theme.PrimaryText}>
            Guest List
          </h3>
        </div>
        <hr style={theme.DividerColor} />
        {!event.hideResponses ? (
          <div className="ColumnNormal" style={{ gap: 14 }}>
            {eventGuestAccountData.length !== 0 ? (
              <div
                className={`AlignedRow ${
                  numUserTickets === 0 ? "BlurFilter" : ""
                }`}
                style={{ gap: 12 }}
              >
                <ProfileSubItemsDisplay
                  users={eventGuestAccountData}
                  size={38}
                  rightComp={
                    numTotalGuests > 7 ? (
                      <span
                        style={{
                          ...theme.PrimaryText,
                          left: 24,
                          position: "relative",
                        }}
                      >
                        +{numTotalGuests - eventGuestAccountData.length} Going
                      </span>
                    ) : undefined
                  }
                />
              </div>
            ) : (
              <div className="AlignedRow" style={{ gap: 12 }}>
                <div
                  className="Centering"
                  style={{
                    ...theme.TertiaryBG,
                    width: 38,
                    height: 38,
                    borderRadius: 100,
                  }}
                >
                  <Icon icon="ion:person" height={20} color={Colors.GRAY1} />
                </div>
                <span style={{ fontSize: 14, ...theme.SubText }}>
                  No one is going yet
                </span>
              </div>
            )}
            {eventGuestAccountData.length > 0 && (
              <div
                className="AlignedRowSpacedSelect"
                style={{
                  width: "100%",
                  pointerEvents: numUserTickets > 0 ? "all" : "none",
                }}
                onClick={() => setDisplayGuestList(true)}
              >
                <span style={{ color: Colors.RED2, fontWeight: 500 }}>
                  {numUserTickets > 0
                    ? "Full Guest List"
                    : "RSVP to See Guest List"}
                </span>
                <IoIosArrowForward size={18} color={Colors.RED2} />
              </div>
            )}
          </div>
        ) : null}
        {displayGuestList && (
          <GuestList setDisplayGuestList={setDisplayGuestList} event={event} />
        )}
      </div>
    </div>
  ) : null;
});
