import { Event, VerificationState } from "@markit/common.types";
import { ThemeProvider } from "@mui/material";
import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  useContext,
} from "react";
import { API } from "../API";
import "../css/FullEvent/FullEvent.css";
import { themeMui } from "./FullEvent/FullEventInputForm";
import { MixpanelContext } from "../context/AnalyticsService";
import {
  getPhoneNumberData,
  getUserData,
  getPhoneNumberSnap,
} from "../utils/FirebaseUtils";
import { formatPhoneNumber } from "../utils/FormatPhoneNumber";
import { Colors } from "../utils/colors";
import {
  MARKIT_HOTLINE_NUMBER,
  isEventExternalLink,
  isEventTicketsPaid,
} from "@markit/common.utils";
import { HiOutlineExclamationCircle } from "react-icons/hi";
import { MARKIT_NUMBER } from "@markit/common.utils";
import { useTheme } from "../hooks/useTheme";
import { checkIsUserFollowing } from "../utils/userUtils";
import RectangleButton from "../components/Buttons/RectangleButton";
import VerificationCodeTextField from "../components/TextFields/VerificationCodeTextField";
import {
  MARKIT_PRIVACY_POLICY,
  MARKIT_TERMS_OF_USE,
} from "@markit/common.utils";
import { firebaseAuth } from "../firebase";
import {
  RecaptchaVerifier,
  signInWithPhoneNumber,
  PhoneAuthProvider,
  signInWithCredential,
} from "firebase/auth";
import { useLogin } from "../hooks/useLogin";
import { Icon } from "@iconify/react";
import { GLOWBOSTON_UID } from "../utils/facebookPixelEvents";

type VerificationCodeButtonProp = {
  event?: Event;
  signIn: boolean;
  buttonText: string;
  tempPhoneNumber: string;
  setTempPhoneNumber?: (tempPhoneNumber: string) => void;
  setNewUid?: (newUid: string) => void;
  verificationState: VerificationState;
  setVerificationState: (verificationState: VerificationState) => void;
  showCodeInputError: boolean;
  setShowCodeInputError: (showCodeInputError: boolean) => void;
  showError: () => boolean;
  afterVerifiedAction?: () => Promise<void>;
  callbackOnVerify?: () => void;
  processing?: boolean;
  isFollowForm?: boolean;
  setAlreadyFollowing?: (alreadyFollowing: boolean) => void;
  alreadyFollowingUid?: string;
  hostFullName?: string;
};

const VerificationCodeButton = (props: VerificationCodeButtonProp) => {
  const {
    event,
    signIn,
    buttonText,
    tempPhoneNumber,
    setTempPhoneNumber,
    setNewUid,
    verificationState,
    setVerificationState,
    showCodeInputError,
    setShowCodeInputError,
    showError,
    afterVerifiedAction,
    callbackOnVerify,
    processing,
    setAlreadyFollowing,
    alreadyFollowingUid,
    hostFullName,
  } = props;
  const mixpanel = useContext(MixpanelContext);
  const { theme } = useTheme();
  const { loginWithCredential } = useLogin();

  const [loading, setLoading] = useState(false);
  const [verificationCode, setVerificationCode] = useState<string[]>(
    Array(6).fill("")
  );
  const [sendingVerificationCode, setSendingVerificationCode] = useState(false);
  const [optedOut, setOptedOut] = useState(false);
  const [existingUser, setExistingUser] = useState(false);
  const [verificationId, setVerificationId] = useState("");

  const ref0 = useRef<HTMLElement>();
  const ref1 = useRef<HTMLElement>();
  const ref2 = useRef<HTMLElement>();
  const ref3 = useRef<HTMLElement>();
  const ref4 = useRef<HTMLElement>();
  const ref5 = useRef<HTMLElement>();
  const refs = useMemo(() => [ref0, ref1, ref2, ref3, ref4, ref5], []);

  const styles = {
    optInText: { fontSize: 10, color: Colors.GRAY2 },
  };

  const sendVerificationCode = useCallback(async () => {
    if (showError()) {
      return;
    }

    setSendingVerificationCode(true);

    if (signIn) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        "sign-in-button",
        {
          size: "invisible",
          callback: (response: any) => {
            console.log("Successfully sent OTP");
          },
        },
        firebaseAuth
      );
      await window.recaptchaVerifier.verify();
      if (mixpanel) {
        mixpanel.track("Webapp: Send Verification Code For Login", {
          customer_phone: tempPhoneNumber,
        });
      }
      signInWithPhoneNumber(
        firebaseAuth,
        tempPhoneNumber,
        window.recaptchaVerifier
      )
        .then(async (confirmationResult) => {
          const phoneNumberSnap = await getPhoneNumberSnap(tempPhoneNumber);
          // mixpanel track if for signing up, not for login verification code
          if (!phoneNumberSnap.exists()) {
            mixpanel.track("Webapp: Start User Sign Up", {
              customer_phone: tempPhoneNumber,
            });
          }
          setVerificationState(VerificationState.VERIFYING);
          setSendingVerificationCode(false);
          setExistingUser(phoneNumberSnap.exists());
          setVerificationId(confirmationResult.verificationId);
        })
        .catch((err) => {
          console.log(err.message);
          alert("Error: " + err.message);
          setSendingVerificationCode(false);
        });
    } else {
      if (event) {
        if (event.locationBasedRSVP) {
          alert("This event requires you to download the app");
          setSendingVerificationCode(false);
          return;
        }
        if (mixpanel) {
          mixpanel.track("Webapp: Send Verification Code", {
            event_id: event.id,
            event_type: isEventTicketsPaid(event) ? "paid" : "free",
            customer_phone: tempPhoneNumber,
            link_type: event.eventType,
          });
        }
      } else {
        if (mixpanel) {
          mixpanel.track("Webapp: Send Verification Code For Follow", {
            customer_phone: tempPhoneNumber,
          });
        }
      }
      // if user has opted out from Markit Number, tell them they need to opt back in
      const phoneNumberData = await getPhoneNumberData(tempPhoneNumber);

      if (phoneNumberData && phoneNumberData.optOut) {
        setOptedOut(true);
        setVerificationState(VerificationState.VERIFYING);
        setSendingVerificationCode(false);
        return;
      }

      if (!showError()) {
        setVerificationState(VerificationState.VERIFYING);
        API.text
          .sendVerificationCode({ toPhoneNumber: tempPhoneNumber })
          .catch(() => {
            setSendingVerificationCode(true);
          })
          .then(() => setSendingVerificationCode(false));
      }
      setSendingVerificationCode(false);
    }
  }, [
    tempPhoneNumber,
    signIn,
    showError,
    mixpanel,
    event,
    setVerificationState,
  ]);

  const verifyPhoneNumber = useCallback(async () => {
    setLoading(true);

    if (verificationCode.filter((el) => el !== undefined).length === 6) {
      API.text
        .verifyCode({
          phoneNumber: tempPhoneNumber,
          code: verificationCode.join(""),
        })
        .then(async (response) => {
          const { uid } = response;
          if (uid !== undefined && setNewUid) {
            setNewUid(uid);
            mixpanel.identify(uid);
          }
          setVerificationState(VerificationState.VERIFIED);

          // for profile page follow flow: check if user is already following user
          if (uid && setAlreadyFollowing) {
            const userData = await getUserData(uid);
            if (userData && alreadyFollowingUid) {
              const checkFollowing = await checkIsUserFollowing(
                uid,
                alreadyFollowingUid
              );
              setAlreadyFollowing(checkFollowing);
            }
          }

          if (event) {
            if (mixpanel) {
              mixpanel.track("Webapp: Successful Verify", {
                event_id: event.id,
                event_type: isEventTicketsPaid(event) ? "paid" : "free",
                customer_phone: tempPhoneNumber,
                link_type: event.eventType,
              });
            }
          } else {
            if (mixpanel) {
              mixpanel.track("Webapp: Successful Verify For Follow", {
                customer_phone: tempPhoneNumber,
              });
            }
          }
          mixpanel.track("Webapp: Agreed to Terms", {
            event_id: event ? event.id : "",
            customer_phone: tempPhoneNumber,
          });

          // If defined, apply extra callback on verify press
          if (callbackOnVerify) {
            callbackOnVerify();
          }
        })
        .catch((err: any) => {
          setShowCodeInputError(true);
          setLoading(false);
          alert("Verification Code is Incorrect");
        });
    } else {
      setShowCodeInputError(true);
      setLoading(false);
      alert("Verification Code is Incorrect");
    }
  }, [
    verificationCode,
    tempPhoneNumber,
    setNewUid,
    setVerificationState,
    setAlreadyFollowing,
    event,
    mixpanel,
    callbackOnVerify,
    alreadyFollowingUid,
    setShowCodeInputError,
  ]);

  const loginWithPhone = useCallback(async () => {
    if (verificationId) {
      setLoading(true);
      const finalCode = verificationCode.join("");
      const credential = PhoneAuthProvider.credential(
        verificationId,
        finalCode
      );
      await API.user
        .createPhoneAuthUser({ phoneNumber: tempPhoneNumber })
        .then(async () => {
          await signInWithCredential(firebaseAuth, credential)
            .then(async (result) => {
              await loginWithCredential(result);
              mixpanel.track("Webapp: Agreed to Terms", {
                customer_phone: tempPhoneNumber,
                signIn: true,
              });
              setVerificationState(VerificationState.VERIFIED);
              setLoading(false);
            })
            .catch((err) => {
              alert("Verification Code is Incorrect");
              setVerificationCode([]);
              console.error("Verification Code Error: " + err.message);
              setShowCodeInputError(true);
              setLoading(false);
            });
        });
    }
  }, [
    loginWithCredential,
    mixpanel,
    setShowCodeInputError,
    setVerificationState,
    tempPhoneNumber,
    verificationCode,
    verificationId,
  ]);

  const resendVerificationCode = useCallback(async () => {
    if (setTempPhoneNumber) {
      setTempPhoneNumber("");
    }
    setVerificationState(VerificationState.UNVERIFIED);
    setExistingUser(false);
    setVerificationId("");
    window.location.reload();
  }, [setTempPhoneNumber, setVerificationState]);

  useEffect(() => {
    if (afterVerifiedAction) {
      if (verificationState === VerificationState.VERIFIED) {
        afterVerifiedAction();
      }
    }
  }, [afterVerifiedAction, verificationCode, verificationState]);

  if (verificationState === VerificationState.UNVERIFIED) {
    return (
      <ThemeProvider theme={themeMui}>
        {/** For when an acount is being converted to subaccount and phone number gets moved */}
        {/* {event?.createdBy !== "WSXfSxoVsUWf4KWHDBVCB1UEi3t2" &&
        hostFullName !== "LA ROB" ? ( */}
        <RectangleButton
          buttonLabel={<span>{buttonText}</span>}
          onPress={sendVerificationCode}
          theme={theme}
          altPaddingVert={12}
          disabled={sendingVerificationCode}
          loading={sendingVerificationCode}
        />
        {/* ) : null} */}
        {/* <p
          style={{{sendingVerificationCode ? "Sending" : "Send Verification Code"}
            fontSize: 12,
            textAlign: "center",
            marginInline: 20,
            color: "#929292",
          }}
        >
          {"We will send a verification code to this number."}
          <br />
          {"Phone number secured through Markit. "}
          <TermsLink href={MARKIT_ATTENDEE_FAQ}>Learn More</TermsLink>
          {isFollowForm && (
            <>
              , <br /> and our{" "}
              <TermsLink href={MARKIT_TERMS_OF_USE}>Terms of Use</TermsLink>,{" "}
              <TermsLink href={MARKIT_PRIVACY_POLICY}>Privacy Policy</TermsLink>
            </>
          )}
        </p> */}
        {/* {!signIn ? (
          <p
            style={{
              fontSize: 12,
              fontWeight: "500",
              textAlign: "center",
              marginInline: 20,
              color: "#929292",
            }}
          >
            <span
              style={{ color: Colors.RED1, fontWeight: "600", fontSize: 14 }}
            >
              T-Mobile users!
            </span>{" "}
            Due to an outage, sign in at the top right of the screen using your
            phone number then return to{" "}
            {event ? "the event page to get tickets" : "this page to subscribe"}
          </p>
        ) : null} */}
      </ThemeProvider>
    );
  } else if (verificationState === VerificationState.VERIFYING) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        {optedOut ? (
          <div
            style={{
              ...theme.TertiaryBG,
              padding: 14,
              borderRadius: "0.5em",
            }}
          >
            <div>
              <div className="AlignedRow">
                <p className="ConfirmTextHeader" style={theme.PrimaryText}>
                  You are opted out of texts
                </p>
                <HiOutlineExclamationCircle
                  style={{ marginLeft: 7 }}
                  size={18}
                  color={Colors.RED2}
                />
              </div>
              <hr style={theme.SubDividerColor} />
              <p
                style={{
                  ...theme.SmallSubText,
                  fontSize: 14,
                  lineHeight: "22px",
                }}
              >
                You have previously opted out of texts from Markit. In order to
                get your ticket, text START to{" "}
                <a
                  href={`tel:${MARKIT_NUMBER}}`}
                  className="SupportLink"
                  style={{ color: Colors.BLUE5, textDecoration: "none" }}
                >
                  {formatPhoneNumber(MARKIT_HOTLINE_NUMBER)}
                </a>
                .
              </p>
            </div>
          </div>
        ) : (
          <>
            {signIn ? (
              <div
                className="LargePopupPanelClose"
                onClick={resendVerificationCode}
                style={{ marginBottom: 14 }}
              >
                <Icon
                  icon="ion:chevron-back"
                  height={24}
                  style={{
                    color: Colors.GRAY3,
                  }}
                />
              </div>
            ) : null}
            <span
              className="LoginHeaderText"
              style={{
                ...theme.PrimaryText,
              }}
            >
              Enter Code
            </span>
            <div style={{ paddingTop: 4, paddingBottom: 14 }}>
              <span className="LoginHeaderTextDescription">
                {`We sent a code to ${tempPhoneNumber}. Enter below to continue`}
              </span>
            </div>
            <div className="textFieldDiv" style={{ alignSelf: "center" }}>
              {Array.from(Array(6).keys()).map((elem, index) => {
                return (
                  <div key={index}>
                    <VerificationCodeTextField
                      index={index}
                      elem={elem}
                      showCodeInputError={showCodeInputError}
                      setShowCodeInputError={setShowCodeInputError}
                      verificationCode={verificationCode}
                      setVerificationCode={setVerificationCode}
                      refs={refs}
                    />
                  </div>
                );
              })}
            </div>
            <div style={{ paddingTop: 14, paddingBottom: 7 }}>
              <span style={{ color: Colors.GRAY2, fontSize: 12 }}>
                {"Didn’t receive a code? "}
              </span>
              <span
                style={{
                  ...theme.LinkColor,
                  fontSize: 12,
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (signIn) {
                    resendVerificationCode();
                  } else {
                    sendVerificationCode();
                    alert("Resent!");
                  }
                }}
              >
                Resend code
              </span>
            </div>
            <div className="ColumnNormal" style={{ paddingBottom: 14, gap: 5 }}>
              <p style={styles.optInText}>
                {"By clicking I AGREE, you agree to our "}
                <span
                  style={{
                    ...theme.LinkColor,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    window.open(MARKIT_TERMS_OF_USE);
                  }}
                >
                  Terms
                </span>
                {" and "}
                <span
                  style={{ ...theme.LinkColor, cursor: "pointer" }}
                  onClick={() => window.open(MARKIT_PRIVACY_POLICY)}
                >
                  Privacy Policy
                </span>
              </p>
              <p style={styles.optInText}>
                By providing your phone number, you agree to receive texts from
                Markit
                {hostFullName && hostFullName !== ""
                  ? " and " + hostFullName
                  : ""}
                , and you agree to our data policies. Msg&Data rates may apply.
                Message frequency varies. Text HELP for help and STOP to cancel.
              </p>
              {event && event.createdBy === GLOWBOSTON_UID ? (
                <p style={styles.optInText}>
                  We use the Meta Pixel to track user interactions and enhance
                  our advertising efforts. For more information on how we use
                  this technology and your data, please read our{" "}
                  <span
                    style={{ ...theme.LinkColor, cursor: "pointer" }}
                    onClick={() => window.open(MARKIT_PRIVACY_POLICY)}
                  >
                    Privacy Policy
                  </span>
                  .
                </p>
              ) : null}
            </div>
            <RectangleButton
              buttonLabel={<span>I Agree</span>}
              onPress={signIn ? loginWithPhone : verifyPhoneNumber}
              theme={theme}
              altPaddingVert={12}
              disabled={loading}
              loading={loading}
            />
          </>
        )}
      </div>
    );
  } else {
    if (event && isEventExternalLink(event.eventType) && callbackOnVerify) {
      return (
        <RectangleButton
          buttonLabel={<span>Continue</span>}
          onPress={callbackOnVerify}
          theme={theme}
          altPaddingVert={12}
          disabled={processing}
          loading={processing}
        />
      );
    }
    return <></>;
  }
};

export default VerificationCodeButton;
