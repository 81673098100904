import React from "react";
import ReactDOM from "react-dom";
import "../src/css/index.css";
import App from "./App";
import { MixpanelProvider } from "./context/AnalyticsService";
import { QueryClient, QueryClientProvider } from "react-query";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./redux/store";
import { initializeApp } from "./redux/sagas/initializationSaga";
import { BrowserRouter } from "react-router-dom";

/*
 * @TODO - Should migrate ContextProvider inside App.
 */
store.dispatch(initializeApp());
const queryClient = new QueryClient();
ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <MixpanelProvider>
            <App />
          </MixpanelProvider>
        </QueryClientProvider>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
