import React, { memo, useMemo } from "react";
import {
  AccountData,
  FormInputType,
  SavedFormQuestion,
  SavedFormResponse,
} from "@markit/common.types";
import { CopyCustomLinkify } from "../Links/CopyCustomLinkify";
import { formatPhoneNumber } from "../../utils/FormatPhoneNumber";

type EssentialDataResponseProps = {
  savedQuestion: SavedFormQuestion;
  formResponse: SavedFormResponse | null | undefined;
  userData: AccountData;
  copyable?: boolean;
};

const EssentialDataResponse = memo(function EssentialDataResponseFn(
  props: EssentialDataResponseProps
) {
  const { savedQuestion, formResponse, userData, copyable } = props;

  const noResponse = useMemo(
    () =>
      formResponse === null ||
      formResponse === undefined ||
      (formResponse &&
        formResponse.responses.every((response: any) => response === "")),
    [formResponse]
  );

  const isCollectPhoneFormQuestion = useMemo(
    () =>
      savedQuestion.formInput.inputType === FormInputType.PHONE &&
      userData &&
      userData.phoneNumber !== "",
    [savedQuestion.formInput.inputType, userData]
  );

  const formResponseToShow = useMemo(
    () =>
      noResponse
        ? "Not Collected"
        : userData && isCollectPhoneFormQuestion
        ? formatPhoneNumber(userData.phoneNumber)
        : formResponse
        ? formResponse.responses.join(", ")
        : "",
    [formResponse, isCollectPhoneFormQuestion, noResponse, userData]
  );

  return copyable ? (
    <CopyCustomLinkify
      text={formResponseToShow}
      textStyles={{ width: "360px" }}
      disableCopy={noResponse}
    />
  ) : (
    <div style={{ maxWidth: "18vw" }}>
      <span className="TextOverflowEllipsis">{formResponseToShow}</span>
    </div>
  );
});

export default EssentialDataResponse;
