import {
  AccountData,
  Conversation,
  IMessage,
  TextTypes,
} from "@markit/common.types";
import "../../../css/GlobalStyles.css";
import { Colors } from "../../../utils/colors";
import LargePopupModalContainer from "../../Containers/LargePopupModalContainer";
import { useSelector } from "react-redux";
import { getAccountState } from "../../../redux/slices/accountSlice";
import { Icon } from "@iconify/react";
import ProfileItem from "../../ProfileItem";
import ConversationsMessagesContainer from "./ConversationsMessagesContainer";
import { TextareaAutosize } from "@mui/material";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { API } from "../../../API";
import { MixpanelContext } from "../../../context/AnalyticsService";
import { getUserConversationsRef } from "../../../utils/FirebaseUtils";
import { limit, onSnapshot, query, where } from "../../../firebase";
import {
  censoredWordsDetected,
  checkIfInExcludedPhones,
  detectedCensored,
  uniqueVals,
} from "@markit/common.utils";
import { DotWave } from "@uiball/loaders";
import { useOnMount } from "../../../utils/useOnMount";
import { fetchSingleConversation } from "../../../utils/FetchSingleUser";
import { getConversationState } from "../../../redux/slices/conversationSlice";
import ConversationAttachmentButton from "./ConversationAttachmentButton";
import AlertButtonWrapper from "../../Containers/AlertButtonWrapper";
import { generate } from "shortid";
import { saveMediaToFirestore } from "../../../utils/photoUtils";
import ConfirmActionModal from "../../Containers/ConfirmPopups/ConfirmActionModal";

type ConversationsSidebarModalProps = {
  conversationUser: AccountData;
  setConversationUser: (conversationUser: AccountData | undefined) => void;
  reopenProfilePreview?: () => void;
  fullProfileOpen: boolean;
};

const ConversationsSidebarModal = (props: ConversationsSidebarModalProps) => {
  const {
    conversationUser,
    setConversationUser,
    reopenProfilePreview,
    fullProfileOpen,
  } = props;
  const { conversations } = useSelector(getConversationState);
  const { userConversations } = conversations;
  const { account } = useSelector(getAccountState);
  const { accountData, followingAccountData } = account;
  const mixpanel = useContext(MixpanelContext);
  const [isSendingMessage, setIsSendingMessage] = useState(false);
  const [messageToSend, setMessageToSend] = useState<string>("");
  const [fetchedAllMessages, setFetchedAllMessages] = useState(false);
  const [loadingConversation, setLoadingConversation] = useState(true);
  const [messageCount, setMessageCount] = useState(15);
  const [messages, setMessages] = useState<IMessage[]>([]);
  const [cursorPosition, setCursorPosition] = useState(messageToSend.length);
  const [alertText, setAlertText] = useState({ header: "", subHeader: "" });

  const [currentImage, setCurrentImage] = useState<string>("");
  const [confirmSendImage, setConfirmSendImage] = useState(false);

  const input = document.getElementById("conversationmodalmessage");
  input?.addEventListener("click", showposition);

  function showposition(event: any) {
    setCursorPosition(event.target.selectionStart);
  }

  // prevent background scrolling when the conversation modal is open
  useOnMount(() => {
    if (conversationUser !== undefined) {
      document.body.style.overflow = "hidden";
    }
    return () => (document.body.style.overflow = "scroll");
  });

  const conversationDisabled = useMemo(
    () =>
      conversationUser &&
      checkIfInExcludedPhones(
        accountData.excludedPhone,
        conversationUser.phoneNumber
      ),
    [accountData.excludedPhone, conversationUser]
  );

  const fetchConversationMessages = useCallback(
    async (increment: boolean) => {
      if (conversationUser) {
        const foundConversation = await fetchSingleConversation(
          accountData.uid,
          conversationUser.uid,
          userConversations
        );

        if (foundConversation) {
          const response = await API.creatorText.fetchUserConversationMessages({
            conversationId: foundConversation.conversationSid,
            twilioUser: accountData,
            attendeeUser: conversationUser,
            messageLimit: increment ? messageCount + 15 : messageCount,
          });
          const { messages } = response;
          if (messages.length === 0) {
            setFetchedAllMessages(true);
          }
          setMessageCount(increment ? messageCount + 15 : messageCount);
          const sortedMessages = messages.sort((x: IMessage, y: IMessage) => {
            return (
              new Date(x.createdAt).getTime() - new Date(y.createdAt).getTime()
            );
          });
          setMessages(uniqueVals(sortedMessages));
          setLoadingConversation(false);
        }
      }
    },
    [accountData, conversationUser, messageCount, userConversations]
  );

  const newIMessage = useCallback(
    (convoData: Conversation) => {
      const iMessage: IMessage = {
        _id: convoData.recentMessage.twilioMessageId,
        text: convoData.recentMessage.text ?? "",
        createdAt: new Date(convoData.recentTimestamp),
        user: {
          _id: convoData.recentMessage.sentBy,
          name: followingAccountData.find(
            (m) => m.uid === convoData.recentMessage.sentBy
          )?.fullName,
          avatar: followingAccountData.find(
            (m) => m.uid === convoData.recentMessage.sentBy
          )?.profilePicURL,
        },
        type: TextTypes.CREATORMESSAGE,
      };
      return iMessage;
    },
    [followingAccountData]
  );

  const sendImage = useCallback(async () => {
    let mediaUrl = "";
    if (currentImage !== "") {
      setIsSendingMessage(true);
      const url = await saveMediaToFirestore(
        currentImage,
        accountData.uid + "/textMedia/chats/" + generate()
      );
      mediaUrl = url;

      await API.creatorText
        .sendMessageToUser({
          twilioUser: accountData,
          attendeeUser: conversationUser,
          message: "\u00A0", // Can't send an empty string as message so send blank character
          mediaUrl: mediaUrl,
        })
        .then(async () => {
          if (mixpanel) {
            mixpanel.track(
              "Conversation: Creator Sent Message To Participant",
              {
                recipient_id: conversationUser.uid,
                from: "webapp",
                message: "Attachment: 1 Image",
                mediaUrl: mediaUrl,
              }
            );
          }
          setIsSendingMessage(false);
          setCurrentImage("");
        })
        .catch((err) => {
          console.log(err.message);
          setIsSendingMessage(false);
          setCurrentImage("");
        });
    }
  }, [accountData, conversationUser, currentImage, mixpanel]);

  const sendTextMessage = useCallback(async () => {
    if (detectedCensored(messageToSend)) {
      setAlertText({
        header: "Content Violation!",
        subHeader: `You used the following banned terms in your message: '${censoredWordsDetected(
          messageToSend
        )}'. Remove these terms and resend.`,
      });
      return;
    }
    setIsSendingMessage(true);
    setMessageToSend("");
    await API.creatorText
      .sendMessageToUser({
        twilioUser: accountData,
        attendeeUser: conversationUser,
        message: messageToSend,
        mediaUrl: "",
      })
      .then(async () => {
        if (mixpanel) {
          mixpanel.track("Conversation: Creator Sent Message To Participant", {
            distinct_id: accountData.uid,
            recipient_id: conversationUser.uid,
            message: messageToSend,
            from: "webapp",
          });
        }
        setIsSendingMessage(false);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [accountData, conversationUser, messageToSend, mixpanel]);

  useEffect(() => {
    (async () => {
      if (conversationUser) {
        const conversationsRef = getUserConversationsRef(accountData.uid);
        const query_ = query(
          conversationsRef,
          where("participantUids", "array-contains", conversationUser.uid),
          limit(1)
        );

        const unsubscribe = onSnapshot(query_, (snapshot) => {
          if (snapshot.empty) {
            setLoadingConversation(false);
            return;
          }
          const conversationData = snapshot.docs.map((doc) => doc.data())[0];
          const iMessage: IMessage = newIMessage(conversationData);
          if (iMessage.text !== "") {
            setMessages((prev) => {
              if (conversationData.recentMessage.sentBy !== accountData.uid) {
                const index = prev.findIndex((c) => c._id === iMessage._id);
                if (index === -1) {
                  prev = prev.concat(iMessage);
                } else {
                  prev.splice(index, 1, iMessage);
                }
              } else {
                prev = prev.concat(iMessage);
              }
              return uniqueVals(prev, (message) => message._id.toString());
            });
          }
        });

        return () => unsubscribe;
      }
    })();
  }, [accountData.uid, conversationUser, followingAccountData, newIMessage]);

  useEffect(() => {
    (async () => {
      await fetchConversationMessages(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversationUser]);

  useEffect(() => {
    (async () => {
      if (currentImage) {
        setConfirmSendImage(true);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentImage]);

  return (
    <>
      <LargePopupModalContainer
        showModal={conversationUser !== undefined}
        headerComp={
          <div>
            <div
              className="LargePopupPanelClose"
              onClick={() => {
                setConversationUser(undefined);
                if (reopenProfilePreview) {
                  reopenProfilePreview();
                }
              }}
            >
              <Icon
                icon={fullProfileOpen ? "ion:chevron-back" : "mdi:close"}
                height={24}
              />
            </div>
            <div style={{ margin: "14px -14px 14px" }}>
              <hr />
            </div>
          </div>
        }
        subHeaderComp={
          <ProfileItem
            profileItem={conversationUser}
            onItemPress={() => {}}
            disabled
            hideViewIcon
          />
        }
        valueComp={
          loadingConversation ? (
            <div style={{ padding: 10 }}>
              <DotWave size={28} speed={1} color={Colors.BLACK} />
            </div>
          ) : (
            <div
              className="ColumnNormal"
              style={{
                margin: "0px -20px 10px",
                height: "calc(100vh - 190px)",
              }}
            >
              <ConversationsMessagesContainer
                messages={messages}
                userId={accountData.uid}
                conversationsMainUser={conversationUser}
                fetchedAllMessages={fetchedAllMessages}
                fetchConversationMessages={() =>
                  fetchConversationMessages(true)
                }
                messageBubbleAltWidth={"70%"}
              />
              <div className="AlignedRow" style={{ paddingTop: 10 }}>
                <ConversationAttachmentButton
                  message={messageToSend}
                  setMessage={setMessageToSend}
                  image={currentImage}
                  setImage={setCurrentImage}
                  cursorPosition={cursorPosition}
                  conversationMainUserId={conversationUser.uid}
                />
                <div className="ConversationsInputContainer">
                  <TextareaAutosize
                    id="conversationmodalmessage"
                    value={messageToSend}
                    placeholder={"Send a message..."}
                    onChange={(e) => {
                      setCursorPosition(e.target.selectionStart);
                      setMessageToSend(e.target.value);
                    }}
                    className="ConversationsInput HideScrollbar"
                    maxRows={5}
                  />
                  <AlertButtonWrapper
                    buttonComp={
                      <div
                        onClick={() => {
                          conversationDisabled
                            ? setAlertText({
                                header:
                                  "You can no longer send messages to this user since they are opted out and unsubscribed.",
                                subHeader: "",
                              })
                            : sendTextMessage();
                        }}
                        style={{
                          pointerEvents:
                            isSendingMessage || messageToSend === ""
                              ? "none"
                              : "all",
                          cursor: "pointer",
                        }}
                      >
                        <span
                          className="AboutSubtitle"
                          style={{
                            marginRight: 14,
                            color:
                              isSendingMessage || messageToSend === ""
                                ? Colors.GRAY1
                                : Colors.BLUE5,
                            cursor: isSendingMessage
                              ? "not-allowed"
                              : "pointer",
                          }}
                        >
                          {isSendingMessage ? "Sending..." : "Send"}
                        </span>
                      </div>
                    }
                    alertTextHeader={alertText.header}
                    alertTextSubHeader={alertText.subHeader}
                    clearAlert={() =>
                      setAlertText({ header: "", subHeader: "" })
                    }
                  />
                </div>
              </div>
            </div>
          )
        }
      />
      <ConfirmActionModal
        heading="Ready to Send?"
        confirmButtonText="Send"
        icon={<Icon icon="ion:paper-plane" height={40} />}
        hideModal={!confirmSendImage}
        setIsVisible={setConfirmSendImage}
        confirmOnPress={() => sendImage()}
        cancelOnPress={() => setCurrentImage("")}
      />
    </>
  );
};

export default ConversationsSidebarModal;
