import { ReactNode, useRef, useEffect, useState, memo } from "react";

interface ExpandableBoxProps {
  children: ReactNode;
}

// Container wrapper that handles smooth animation from one stage to the next stage within the same container
const ExpandableBox = ({ children }: ExpandableBoxProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState<number | "auto">("auto");

  useEffect(() => {
    // Add short timeout to stabilize the child element height
    setTimeout(() => {
      if (
        containerRef.current &&
        height !== containerRef.current.offsetHeight
      ) {
        setHeight(containerRef.current.offsetHeight);
      }
    }, 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [children]);

  return (
    <div
      style={{
        height: `${height}px`,
        minHeight: `${height}px`,
        maxHeight: `${height}px`,
        overflowX: "visible",
        overflowY: "clip",
        transition: "all 0.2s ease-in-out",
      }}
    >
      <div ref={containerRef}>{children}</div>
    </div>
  );
};

export default memo(ExpandableBox);
