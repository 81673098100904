import { isPhoneNumberValid } from "./FormatPhoneNumber";
import { formatPhoneNumber, parsePhoneNumber } from "react-phone-number-input";
import {
  MARKIT_HOTLINE_NUMBER,
  PhoneErrorType,
  SupportedCountries,
} from "@markit/common.utils";

// Validate phone number input
export const showPhoneInputError = (
  phoneNumber: string,
  setShowPhoneNumberError: (error: PhoneErrorType) => void
) => {
  let error = false;
  const parsedPhoneNumber = parsePhoneNumber(phoneNumber);
  // Check if phone number is empty
  if (!phoneNumber || (phoneNumber && phoneNumber.length === 0)) {
    setShowPhoneNumberError({
      isError: true,
      message: "You must enter a phone number.",
    });
    error = true;
  } else if (
    parsedPhoneNumber?.country &&
    !SupportedCountries.includes(parsedPhoneNumber.country)
  ) {
    // Check if country is supported by our maintained list
    setShowPhoneNumberError({
      isError: true,
      message: `This country (${
        parsedPhoneNumber.country
      }) is currently not supported. Contact us at ${formatPhoneNumber(
        MARKIT_HOTLINE_NUMBER
      )} to get support.`,
    });
    error = true;
  } else if (!isPhoneNumberValid(phoneNumber)) {
    // Check if phone number format is valid based on the country code
    setShowPhoneNumberError({
      isError: true,
      message: "Please enter a valid mobile phone number.",
    });
    error = true;
  }
  return error;
};
